<template>
  <MetaProvider>
    <Toast />
    <RouterView />
  </MetaProvider>
</template>

<script setup lang="ts">
import { useCookieTracking } from "@/composables/useCookieTracking";
import MetaProvider from "@/ui/layouts/MetaProvider.vue";
import { eventBus } from "@/utils/eventBus";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { onMounted } from "vue";
import { RouterView } from "vue-router";
import { useEventTypesStore } from "./stores/eventTypesStore";

const { getTrackingCookies } = useCookieTracking();

const gtmId = import.meta.env.VITE_GTM_ID;

const { loadEventTypes } = useEventTypesStore();

const initGTM = () => {
  if (import.meta.env.VITE_NODE_ENV === "production") {
    // Insert Google Tag Manager header script
    const gtmScript = document.createElement("script");
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(gtmScript);

    // Insert Google Tag Manager noscript for the body
    document.body.insertAdjacentHTML(
      "afterbegin",
      `
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
    `,
    );

    console.log("GTM script added");
  } else {
    console.log("Google Tag Manager is disabled in development mode");
  }
};

const initPAP = () => {
  const script1 = document.createElement("script");
  script1.setAttribute("type", "text/javascript");
  script1.setAttribute("id", "pap_x2s6df8d");
  script1.setAttribute("src", "https://4cpredictions.postaffiliatepro.com/scripts/26fjkh");
  document.head.appendChild(script1);

  window.onload = () => {
    const script2 = document.createElement("script");
    script2.setAttribute("type", "text/javascript");
    script2.textContent = `
      function getAllUrlParams(url) {
          var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
          var obj = {};
          if (queryString) {
              queryString = queryString.split('#')[0];
              var arr = queryString.split('&');
              for (var i = 0; i < arr.length; i++) {
                  var a = arr[i].split('=');
                  var paramName = a[0];
                  var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
                  paramName = paramName.toLowerCase();
                  paramValue = decodeURIComponent(paramValue);

                  if (obj[paramName]) {
                      if (Array.isArray(obj[paramName])) {
                          obj[paramName].push(paramValue);
                      } else {
                          obj[paramName] = [obj[paramName], paramValue];
                      }
                  } else {
                      obj[paramName] = paramValue;
                  }
              }
          }
          return obj;
      }

      function setCookie(name, value, days) {
          var expires = new Date(Date.now() + days * 864e5).toUTCString();
          document.cookie = name + "=" + value + "; expires=" + expires + "; path=/; Secure; SameSite=Lax";
      }

      function getCookie(name) {
          var value = "; " + document.cookie;
          var parts = value.split("; " + name + "=");
          if (parts.length === 2) return parts.pop().split(";").shift();
      }

      const params = getAllUrlParams();

      const existingPapVisitorId = getCookie('4c_pap_visitor_id');

      if (params.pap_visitors_id) {
          setCookie('4c_pap_visitor_id', params.pap_visitors_id, 7);
      } else if (existingPapVisitorId) {
          console.log('PAP_Visitors_ID cookie already exists:', existingPapVisitorId);
      } else {
          console.log('Generating a new - PAP_Visitors_ID');

          function checkVisitorId(retries, delay) {
              if (retries <= 0) {
                  console.warn("PAP_Visitors_ID could not be retrieved.");
                  return;
              }

              const PAP_Visitors_ID = PostAffTracker.getVisitorId();
              if (PAP_Visitors_ID) {
                  setCookie('4c_pap_visitor_id', PAP_Visitors_ID, 7);
                  console.log("Generated and set PAP_Visitors_ID:", PAP_Visitors_ID);
              } else {
                  setTimeout(() => checkVisitorId(retries - 1, delay), delay);
              }
          }

          PostAffTracker.setAccountId('default1');
          try {
              PostAffTracker.track();
              PostAffTracker.setCookieDomain('platform.4cpredictions.ai');

              checkVisitorId(3, 500);
          } catch (err) {
              console.error("Error during PostAffTracker execution:", err);
          }
      }
    `;
    document.body.appendChild(script2);
  };
};

const onInit = () => {
  const toast = useToast();
  eventBus.addToast = toast.add;
  loadEventTypes();
};

onMounted(() => {
  initGTM();
  initPAP();
  getTrackingCookies();
  onInit();
});
</script>
