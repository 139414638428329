<script setup lang="ts">
const brands = [
  "1.webp",
  "2.webp",
  "3.webp",
  "4.webp",
  "5.webp",
  "6.webp",
  "7.webp",
  "8.webp",
  "9.webp",
  "10.webp",
  "11.webp",
];
</script>

<template>
  <div class="group relative mb-6 w-full overflow-hidden whitespace-nowrap py-8">
    <div
      class="pointer-events-none absolute left-0 top-0 z-10 h-full w-16 bg-gradient-to-r from-white to-transparent dark:from-black"
    ></div>

    <div
      class="pointer-events-none absolute right-0 top-0 z-10 h-full w-16 bg-gradient-to-l from-white to-transparent dark:from-black"
    ></div>

    <div class="animate-marquee flex w-max group-hover:[animation-play-state:paused]">
      <template v-for="n in 2" :key="n">
        <div
          v-for="(brand, index) in brands"
          :key="`${n}-${index}`"
          class="flex flex-shrink-0 items-center justify-center px-4 sm:px-8"
        >
          <img
            :src="`/images/brands/${brand}`"
            :alt="`brand-${index}`"
            class="h-5 opacity-80 transition-opacity duration-300 ease-in-out hover:opacity-100 sm:h-8"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  animation: marquee 20s linear infinite;
}
</style>
