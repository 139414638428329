export default class StringHelper {
  static ucFirst = (str: string): string => {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  static keyFromRecord = (record: Record<string, string>) => {
    return Object.entries(record)
      .filter(([key, value]) => {
        return key !== "q" && value && value.trim() !== "";
      })
      .map(([, value]) => value.replace(/-/g, "_"))
      .join("_");
  };

  static truncateText = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + " ...";
  };

  static extractNameParts = (fullname: string): { first_name: string; last_name: string } => {
  const parts = fullname.trim().split(/\s+/);

  const first_name = parts[0] || "";
  const last_name = parts.length > 1 ? parts.slice(1).join(" ") : "";

  return { first_name, last_name };
}
}
