<template>
  <PlainLayout>
    <div class="mx-auto flex max-w-[1440px] flex-col gap-5 px-6 py-6 md:py-12">
      <div class="flex items-center gap-5 border-b-2 border-gray-200 pb-5">
        <Avatar
          shape="circle"
          size="large"
          class="h-[60px] w-[60px] text-lg font-semibold"
          :label="`${authStore.user.first_name[0]}${authStore.user.last_name[0]}`"
        />
        <div class="leading-2 flex flex-col gap-0">
          <span class="leading-2 text-lg font-semibold"
            >{{ authStore.user.first_name }} {{ authStore.user.last_name }}</span
          >
          <span class="text-sm text-gray-400">Joined {{ authStore.user.joined_date }}</span>
        </div>
      </div>

      <div v-if="subscriptions && subscriptions.total > 0" class="flex flex-col gap-4 md:flex-row">
        <div class="flex flex-1 flex-col rounded-lg bg-white p-6 shadow-sm">
          <div
            class="mb-3 flex h-[35px] w-[35px] items-center justify-center rounded-md bg-[#A855F7]"
          >
            <div class="h-4 w-4">
              <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.2678 2.5466L4.39229 1.42211L5.51678 2.5466C5.7836 2.81342 6.2162 2.81342 6.48302 2.5466L7.60751 1.42211L8.732 2.5466C8.99883 2.81342 9.43143 2.81342 9.69824 2.5466L10.2199 2.02496V11.975L9.69824 11.4534C9.43142 11.1866 8.99882 11.1866 8.732 11.4534L7.60751 12.5779L6.48302 11.4534C6.2162 11.1866 5.7836 11.1866 5.51678 11.4534L4.39229 12.5779L3.2678 11.4534C3.00098 11.1866 2.56838 11.1866 2.30156 11.4534L1.77993 11.975V2.02496L2.30156 2.5466C2.56838 2.81342 3.00098 2.81342 3.2678 2.5466ZM4.87541 0.200099C4.60859 -0.066719 4.17599 -0.0667213 3.90917 0.200099L2.78468 1.32459L1.74057 0.28048C1.31016 -0.149936 0.574219 0.154907 0.574219 0.7636V13.2364C0.574219 13.8451 1.31016 14.1499 1.74057 13.7195L2.78468 12.6754L3.90917 13.7999C4.17599 14.0667 4.60859 14.0667 4.87541 13.7999L5.9999 12.6754L7.12439 13.7999C7.39121 14.0667 7.82381 14.0667 8.09063 13.7999L9.21512 12.6754L10.2592 13.7195C10.6896 14.1499 11.4256 13.8451 11.4256 13.2364V0.7636C11.4256 0.154905 10.6896 -0.149935 10.2592 0.28048L9.21512 1.32459L8.09063 0.200099C7.82381 -0.066719 7.39121 -0.0667213 7.12439 0.200099L5.9999 1.32459L4.87541 0.200099ZM4.39229 3.98575C4.05935 3.98575 3.78944 4.25565 3.78944 4.5886C3.78944 4.92155 4.05935 5.19146 4.39229 5.19146H7.60751C7.94046 5.19146 8.21037 4.92155 8.21037 4.5886C8.21037 4.25565 7.94046 3.98575 7.60751 3.98575H4.39229ZM3.78944 7.00002C3.78944 6.66707 4.05935 6.39716 4.39229 6.39716H7.60751C7.94046 6.39716 8.21037 6.66707 8.21037 7.00002C8.21037 7.33296 7.94046 7.60287 7.60751 7.60287H4.39229C4.05935 7.60287 3.78944 7.33296 3.78944 7.00002ZM4.39229 8.80858C4.05935 8.80858 3.78944 9.07849 3.78944 9.41143C3.78944 9.74438 4.05935 10.0143 4.39229 10.0143H7.60751C7.94046 10.0143 8.21037 9.74438 8.21037 9.41143C8.21037 9.07849 7.94046 8.80858 7.60751 8.80858H4.39229Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <span :class="`text-lg font-semibold ${loading ? 'text-gray-300' : ''}`"
            >${{ loading || !subscriptions ? 0 : subscriptions.total.toFixed(2) }}p/m</span
          >
          <span class="text-sm text-gray-400">Current Subscription</span>
        </div>
      </div>

      <div v-if="loading" class="flex flex-col gap-3">
        <ModelCardSkeleton v-for="(item, index) in Array.from({ length: 1 })" :key="index" />
      </div>

      <Button
        v-if="!loading && hasAccess"
        class="h-8 w-full border-sky-500 bg-transparent px-5"
        @click="
          router.push({
            name: 'profile-manage-subscription',
            query: { subscriptionType: subscriptions.total > 0 ? 'new' : 'old' },
          })
        "
      >
        <span class="leading-0 text-xs font-semibold text-sky-500">Manage Subscription</span>
      </Button>
    </div>
  </PlainLayout>
</template>

<script setup lang="ts">
import { userApi } from "@/api/userApi";
import { useAuthStore } from "@/stores/authStore";
import type { Subscriptions } from "@/types/user";
import ModelCardSkeleton from "@/ui/components/models/ModelCardSkeleton.vue";
import PlainLayout from "@/ui/layouts/PlainLayout.vue";
import { showGlobalToast } from "@/utils/toast";
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

const authStore = useAuthStore();
const loading = ref(false);
const subscriptions = ref<Subscriptions>({ total: 0, models: [] });
const profile = ref(null);
const router = useRouter();
const hasNewSubscription = ref(false);
const hasAccess = ref(false);

const getSubscriptions = async () => {
  loading.value = true;
  try {
    subscriptions.value = await userApi.subscriptions();
    console.log(subscriptions.value);
    hasNewSubscription.value = Number(subscriptions.value.total) > 0;
  } catch (err) {
    console.log(err);
    showGlobalToast("error", "", "An error occurred, please try again.");
  }
};

const getProfile = async () => {
  loading.value = true;
  try {
    profile.value = await userApi.profile();
    console.log(subscriptions.value);
    subscriptions.value.total = Number(profile.value.data.unit_price) || 0;
    hasAccess.value = profile.value.data.has_access;
    console.log(hasAccess.value);
    if (!hasAccess.value) {
      subscriptions.value.total = 0;
    }
  } catch (err) {
    console.log(err);
    showGlobalToast("error", "", "An error occurred, please try again.");
  } finally {
    loading.value = false;
  }
};

watch(
  () => authStore.user,
  (user) => {
    if (!user) {
      window.location.href = "/";
    }
  },
  { immediate: true },
);

onMounted(() => {
  getProfile();
  getSubscriptions();
});
</script>
