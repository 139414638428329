import axiosInstance from '@/api/axiosInstance';
import type { Subscriptions, User } from '@/types/user';
import { type AxiosInstance } from 'axios';

const api: AxiosInstance = axiosInstance;


export const userApi = {
    async subscriptions(): Promise<Subscriptions> {
        const response = await api.get('/user/subscriptions');
        return response.data;
    },
    async profile() {
      const response = await api.get('/user/profile');
      return response.data;
    },
    async forgot(data) {
        await api.get('/sanctum/csrf-cookie');
        const response = await api.post('/forgot-password', data);
        return response;
    },
    async resendPasswordLink(data) {
        await api.get('/sanctum/csrf-cookie');
        const response = await api.post('/resend-password-link', data);
        return response;
    },
    async reset(data) {
        await api.get('/sanctum/csrf-cookie');
        const response = await api.post('/reset-password', data);
        return response;
    },

    async register(data): Promise<User> {
        await api.get('/sanctum/csrf-cookie');
        const response = await api.post('/register', data);
        return response.data;
    },

    async emailVerify(id: string, hash: string, query: Record<string, string>) {
        const queryString = new URLSearchParams(query).toString();
        const response = await api.get(`/email/verify/${id}/${hash}?${queryString}`);
        return response.data;
    },

    async logout() {
        const response = await api.post('/logout');
        return response.data.data;
    },

    async fetchUser(): Promise<User> {
        await api.get('/sanctum/csrf-cookie');
        const response = await api.get('/user');
        return response.data.data;
    },

    async changeLanguage(lang: string) {
        const response = await api.post('/auth/user/change-language', { language: lang });
        return response.data.data;
    },

    async submitABug(data): Promise<boolean> {
        const response = await api.post('/user/submit-bug', data);
        return response.status == 200;
    },

    async contactUs(data): Promise<boolean> {
        const response = await api.post('/user/contact-us', data);
        return response.status == 200;
    },

    async cancelSubscription(data): Promise<boolean> {
        const response = await api.post('/user/cancel-subscription', data);
        return response.status == 200;
    },

    async cancelStripeSubscription(data: { reason: string }) {
      const response = await api.post('/user/cancel-stripe-subscription', data);
      return response;
    }

};
