<script setup lang="ts">
import AccessImage from "@/assets/images/builders/access.webp";
import AwardsImage from "@/assets/images/builders/awards.webp";
import BlocksImage from "@/assets/images/builders/blocks.webp";
import BoxInverseImage from "@/assets/images/builders/box-inverse.webp";
import BoxImage from "@/assets/images/builders/box.webp";
import DataImage from "@/assets/images/builders/data-2.webp";
import EarningsImage from "@/assets/images/builders/earnings.webp";
import FollowersImage from "@/assets/images/builders/followers.webp";
import IntegrationImage from "@/assets/images/builders/integration.webp";
import NetworkImage from "@/assets/images/builders/network.webp";
import PromotionImage from "@/assets/images/builders/promotion.webp";
import { useRouter } from "vue-router";
import BuilderBanner from "./partials/BuilderBanner.vue";
import BuilderFooter from "./partials/BuilderFooter.vue";
import BuilderHeader from "./partials/BuilderHeader.vue";

const router = useRouter();

const blocks = [
  {
    title: "Showcase predictive excellence",
    image: BoxImage,
    text: "Win the prestigious AI Model Showdown medal for the most accurate predictive model of the season.",
  },
  {
    title: "Achieve widespread recognition",
    image: BoxInverseImage,
    text: "Be awarded the coveted 4C Prediction Awards for the most popular model among our followers across various categories.",
  },
  {
    title: "Earn Revenue Through Our Platform",
    image: BlocksImage,
    text: "4C offers a channel to market through which you earn real revenue off the back of your AI Models. We reward our creators by sharing revenue and reach.",
  },
];
</script>

<template>
  <div class="min-w-screen min-h-screen bg-[#020617]">
    <BuilderHeader />
    <BuilderBanner>
      <p class="text-primary">
        Where the world's smartest AI model builders share their predictions.
      </p>
      <div
        class="w-full text-[50px] font-medium leading-[50px] text-white md:text-[70px] md:leading-[70px]"
      >
        Your Predictions.<br />
        Your Prestige.<br />
        <span class="font-extrabold text-primary">Your Profit!</span>
      </div>
      <div class="text-white">
        <p>
          Get your model in the spotlight! Join the top AI platform powering predictions across
          industries!
        </p>
      </div>
      <div>
        <Button @click="router.push({ name: 'creator.join' })" class="w-full px-6 md:w-auto">
          <span class="text-nowrap font-bold text-black">Join Now!</span>
        </Button>
      </div>
    </BuilderBanner>
    <section class="bg-white px-4 md:px-0" id="why-apply">
      <div class="mx-auto py-[80px] md:max-w-[1312px]">
        <h1 class="text-center text-[50px] leading-[50px] md:text-[60px] md:leading-[60px]">
          Why Apply?
        </h1>
        <p class="mx-auto mt-8 text-center text-[18px] md:max-w-[600px]">
          Get your model in the spotlight! Join the top AI platform powering predictions across
          industries!
        </p>
        <div
          class="mt-[80px] grid grid-cols-1 gap-[24px] sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3"
        >
          <div
            v-for="(item, index) in blocks"
            :key="index"
            class="flex flex-col gap-5 rounded-[40px] bg-primary p-[40px]"
          >
            <img :src="item.image" class="mb-[40px] h-[56px] w-[56px]" />
            <h2 class="text-[32px] font-medium leading-[35px]">{{ item.title }}</h2>
            <p>{{ item.text }}</p>
          </div>
        </div>
        <div class="mt-12 flex items-center justify-center">
          <Button
            @click="router.push({ name: 'creator.join' })"
            class="w-full border-black bg-black px-12 md:w-auto"
          >
            <span class="text-[17px] font-semibold text-white">Join Now</span>
          </Button>
        </div>
      </div>
    </section>
    <section id="why-worth" class="px-4 md:px-0">
      <div class="mx-auto py-[80px] md:max-w-[1312px]">
        <h1
          class="text-center text-[50px] leading-[50px] text-white md:text-[60px] md:leading-[60px]"
        >
          <span class="text-primary">What's</span> in it for you?
        </h1>
        <!-- One -->
        <div
          class="mt-[80px] grid grid-cols-1 gap-[24px] sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3"
        >
          <div
            class="flex h-[384px] flex-col justify-end gap-[16px] rounded-[32px] border border-[#fff3] bg-gradient-to-b from-[#ffffff1a] to-black bg-clip-padding p-[32px]"
          >
            <div class="mt-10">
              <img :src="FollowersImage" />
              <h2 class="mt-4 text-[24px] text-primary">Prestige & Recognition</h2>
              <p class="text-white">
                Feature in the 4C Predictions Hall of Fame and gain followers and reputation.
              </p>
            </div>
          </div>
          <div
            :style="{
              backgroundImage: `url(${AwardsImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
            class="relative flex h-[384px] flex-col justify-end gap-[16px] overflow-hidden rounded-[32px] border border-[#fff3] bg-clip-padding"
          >
            <div
              class="absolute z-[1] h-full w-full bg-gradient-to-b from-[#ffffff1a] to-black"
            ></div>
            <div class="absolute z-[2] p-[32px]">
              <h2 class="text-[24px] text-primary">Annual awards</h2>
              <p class="text-white">Top performers receive certificates and public recognition.</p>
            </div>
          </div>
          <div
            :style="{
              backgroundImage: `url(${IntegrationImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
            class="relative flex h-[384px] flex-col justify-end gap-[16px] overflow-hidden rounded-[32px] border border-[#fff3] bg-clip-padding"
          >
            <div
              class="absolute z-[1] h-full w-full bg-gradient-to-b from-[#ffffff1a] to-black"
            ></div>
            <div class="absolute z-[2] p-[32px]">
              <h2 class="text-[24px] text-primary">ORCiD Integration</h2>
              <p class="text-white">
                Enhance your academic credibility with top-model visibility on ORCiD and other
                academic platforms coming soon.
              </p>
            </div>
          </div>
        </div>

        <!-- Two -->
        <div class="mt-10 grid grid-cols-1 gap-[24px] sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
          <div
            :style="{
              backgroundImage: `url(${AccessImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
            class="relative flex h-[384px] flex-col justify-end gap-[16px] overflow-hidden rounded-[32px] border border-[#fff3] bg-clip-padding"
          >
            <div
              class="absolute z-[1] h-full w-full bg-gradient-to-b from-[#ffffff1a] to-black"
            ></div>
            <div class="absolute z-[2] p-[32px]">
              <h2 class="text-[24px] text-primary">Subsidized Open Access</h2>
              <p class="text-white">
                Up to $2,000.00 for open access publication fees linked to your model. T&C's Apply.
              </p>
            </div>
          </div>
          <div
            :style="{
              backgroundImage: `url(${DataImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
            class="relative flex h-[384px] flex-col justify-end gap-[16px] overflow-hidden rounded-[32px] border border-[#fff3] bg-clip-padding"
          >
            <div
              class="absolute z-[1] h-full w-full bg-gradient-to-b from-[#ffffff1a] to-black"
            ></div>
            <div class="absolute z-[2] p-[32px]">
              <h2 class="text-[24px] text-primary">Access to Data</h2>
              <p class="text-white">
                Free access to sports performance data, with more databases<br />
                coming soon.
              </p>
            </div>
          </div>
        </div>

        <!-- Three -->
        <div class="mt-10 grid grid-cols-1 gap-[24px] sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
          <div
            :style="{
              backgroundImage: `url(${EarningsImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
            class="relative flex h-[384px] flex-col justify-end gap-[16px] overflow-hidden rounded-[32px] border border-[#fff3] bg-clip-padding"
          >
            <div
              class="absolute z-[1] h-full w-full bg-gradient-to-b from-[#ffffff1a] to-black"
            ></div>
            <div class="absolute z-[2] p-[32px]">
              <h2 class="text-[24px] text-primary">Earnings</h2>
              <p class="text-white">
                We are the channel to market for your genius. We share revenue with our creators and
                want you to get paid. T&C's Apply.
              </p>
            </div>
          </div>
          <div
            :style="{
              backgroundImage: `url(${NetworkImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
            class="relative flex h-[384px] flex-col justify-end gap-[16px] overflow-hidden rounded-[32px] border border-[#fff3] bg-clip-padding"
          >
            <div
              class="absolute z-[1] h-full w-full bg-gradient-to-b from-[#ffffff1a] to-black"
            ></div>
            <div class="absolute z-[2] p-[32px]">
              <h2 class="text-[24px] text-primary">Networking & Community</h2>
              <p class="text-white">
                Connect and Collaborate with AI and predictive analytics experts.
              </p>
            </div>
          </div>
          <div
            :style="{
              backgroundImage: `url(${PromotionImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
            class="relative flex h-[384px] flex-col justify-end gap-[16px] overflow-hidden rounded-[32px] border border-[#fff3] bg-clip-padding"
          >
            <div
              class="absolute z-[1] h-full w-full bg-gradient-to-b from-[#ffffff1a] to-black"
            ></div>
            <div class="absolute z-[2] p-[32px]">
              <h2 class="text-[24px] text-primary">Professional Promotion</h2>
              <p class="text-white">
                We'll help promote both you and your model across platforms. Putting you in an elite
                class of creators who build exceptional predictive models.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="join" class="bg-white px-4 md:px-0">
      <div class="mx-auto py-[80px] md:max-w-[1312px]">
        <h1 class="text-center text-[50px] leading-[50px] md:text-[60px] md:leading-[60px]">
          Ready to Apply?
        </h1>
        <p class="py-8 text-center">
          Don't miss your chance to be part of our network of elite model builders. Join Now
        </p>
        <div class="flex items-center justify-center">
          <Button @click="router.push({ name: 'creator.join' })" class="px-12">
            <span class="text-[17px] font-semibold">Join Now</span>
          </Button>
        </div>
      </div>
    </section>
    <BuilderFooter />
  </div>
</template>
