<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from "vue";

defineProps<{
  isFooter?: boolean;
}>();

const isMobile = ref(false);
const activeSection = ref("#home");
const emit = defineEmits(["clicked"]);

const navItems = [
  { title: "Home", link: "#home" },
  { title: "Stats", link: "#stats" },
  { title: "About 4C", link: "#about" },
  { title: "Our Users", link: "#users" },
  { title: "Get Predictions", link: "#predictions" },
];

const observer = ref<IntersectionObserver | null>(null);

const scrollToView = (to?: string) => {
  const nav = (to ?? window.location.hash) || "#home";
  const element = document.querySelector(nav);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    activeSection.value = nav;
    if (nav !== "#home") {
      history.replaceState(history.state, "", nav);
    }
  }
};

const handleClick = (to: string) => {
  scrollToView(to);
  emit("clicked");
};

const observeSections = () => {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.6,
  };

  observer.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        activeSection.value = `#${entry.target.id}`;
      }
    });
  }, options);

  navItems.forEach((item) => {
    const section = document.querySelector(item.link);
    if (section) observer.value?.observe(section);
  });
};

onMounted(() => {
  scrollToView();
  isMobile.value = window.innerWidth < 768;
  observeSections();
});

onBeforeUnmount(() => {
  observer.value?.disconnect();
});
</script>

<template>
  <nav :class="{ 'flex-col gap-4': isMobile, 'gap-5': !isMobile }" class="flex">
    <a
      v-for="(item, index) in navItems"
      :key="index"
      :class="[
        'cursor-pointer text-nowrap transition-colors duration-300',
        item.link === activeSection ? 'text-primary' : 'text-white',
        isFooter && 'text-center md:text-start',
      ]"
      @click.prevent="handleClick(item.link)"
    >
      {{ item.title }}
    </a>
  </nav>
</template>
