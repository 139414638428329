//import confetti from "canvas-confetti";

import confetti from "canvas-confetti";

/**
 * Launch a simple confetti effect.
 */
export function launchConfetti(): void {
  confetti({
    angle: 45,
    particleCount: 500,
    spread: 12970,
    origin: { y: 0.5 },
    gravity: 0.5,
  });
}

/**
 * Launch a burst confetti effect for a duration.
 * @param duration - Duration in milliseconds (default: 3 seconds).
 */
export function burstConfetti(duration: number = 3000): void {
  const end = Date.now() + duration;

  (function frame() {
    confetti({
      particleCount: 5,
      spread: 80,
      origin: { y: 0.6 },
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  })();
}

export function fullScreenConfetti(): void {
  confetti({
    particleCount: 150,
    spread: 160,
    startVelocity: 30,
    origin: { x: Math.random(), y: Math.random() - 0.2 },
  });
}
