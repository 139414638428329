<template>
  <form @submit.prevent="doRegister">
    <!-- Email Address Field -->
    <div class="mt-4 inline-flex w-full items-start justify-start gap-2.5 self-stretch">
      <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]">
        <div class="self-stretch text-xs font-bold leading-none text-slate-700">Email Address</div>
        <InputText
          v-model="emailAddress"
          @blur="v$.emailAddress.$touch()"
          :class="{ 'p-invalid': v$.emailAddress.$invalid && v$.emailAddress.$dirty }"
          type="email"
          class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
        />
        <div
          v-if="v$.emailAddress.$dirty && v$.emailAddress.$invalid"
          class="p-error mt-1 text-xs text-red-600"
        >
          <div v-if="(v$.emailAddress.required as any)?.$invalid">Email is required.</div>
          <div v-else-if="(v$.emailAddress.email as any)?.$invalid">
            Please enter a valid email address.
          </div>
        </div>
      </div>
      <!-- The DOB field is commented out -->
    </div>

    <!-- Password Field -->
    <div class="mt-4 flex w-full flex-col items-start justify-start gap-[7px]">
      <div class="text-xs font-semibold leading-none text-slate-700">Password</div>
      <Password
        v-model="password"
        @blur="v$.password.$touch()"
        :class="{ 'p-invalid': v$.password.$invalid && v$.password.$dirty }"
        toggleMask
        :feedback="false"
        class="h-[33px] w-full border-slate-300 bg-white text-sm font-normal text-slate-500"
      />
      <div
        v-if="v$.password.$dirty && v$.password.$invalid"
        class="p-error mt-1 text-xs text-red-600"
      >
        <div v-if="(v$.password.required as any)?.$invalid">Password is required.</div>
        <div v-else-if="(v$.password.minLength as any)?.$invalid">
          Password must be at least 8 characters long.
        </div>
      </div>
    </div>

    <!-- Terms and Conditions Checkbox -->
    <div class="mt-2 flex flex-col items-start">
      <div class="flex items-center gap-[7px]">
        <Checkbox
          v-model="acceptTerms"
          @blur="v$.acceptTerms.$touch()"
          :class="{ 'p-invalid': v$.acceptTerms.$invalid && v$.acceptTerms.$dirty }"
          inputId="acceptTerms"
          name="acceptTerms"
          :binary="true"
        />
        <label
          for="acceptTerms"
          class="cursor-pointer text-xs font-bold leading-[17.50px] text-slate-700"
        >
          I understand predictions may not always be accurate and I have read and accept 4C
          Predictions
          <router-link to="/terms-of-use" class="underline">Terms and Conditions</router-link>
          and
          <router-link to="/privacy-policy" class="underline">Privacy Policy.</router-link>
        </label>
      </div>
      <div
        v-if="v$.acceptTerms.$dirty && v$.acceptTerms.$invalid"
        class="p-error mt-1 text-xs text-red-600"
      >
        <div v-if="(v$.acceptTerms.required as any)?.$invalid">You must accept the terms.</div>
      </div>
    </div>
    <!-- Display Backend Error Message -->
    <Button type="submit" class="my-2 w-full px-[10.50px] py-[7px]">
      <i v-if="authStore.isLoading" class="pi pi-spin pi-spinner"></i>
      <div class="text-sm font-medium text-slate-700">Join Now</div>
    </Button>
  </form>
</template>

<script setup lang="ts">
import { authApi } from "@/api/authApi";
import { paymentApi } from "@/api/paymentApi";
import { useCookieTracking } from "@/composables/useCookieTracking";
import { useDataLayer } from "@/composables/useDataLayer";
import { useAuthStore } from "@/stores/authStore";
import { showGlobalToast } from "@/utils/toast";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, minLength, required, sameAs } from "@vuelidate/validators";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { computed, ref } from "vue";

const authStore = useAuthStore();

const { getTrackingCookies } = useCookieTracking();
const { pushToDataLayer } = useDataLayer();

const emailAddress = ref("");
const password = ref("");
const acceptTerms = ref(false);
const backendError = ref("");

const rules = computed(() => ({
  emailAddress: { required, email },
  password: { required, minLength: minLength(8) },
  acceptTerms: { required: helpers.withMessage("You must accept the terms.", sameAs(true)) },
}));

const v$ = useVuelidate(rules, {
  emailAddress,
  password,
  acceptTerms,
});

const downloadFile = (filePath: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = filePath;
  link.setAttribute("download", fileName);
  link.click();
  showGlobalToast("success", "", `${fileName} downloaded`);
};

const requestPaymentLink = async () => {
  const link = window.location.origin + (authStore.redirectUrl ?? "/events/march-madness");
  const result = await paymentApi.requestLink(authStore.user, link);
  authStore.setAuthModalVisible(false);
  authStore.setRedirectUrl(null);
  authStore.setAuthAndGoPay(false);
  window.location.href = result.payment_url;
};

const doRegister = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect) {
    authStore.setIsLoading(true);
    try {
      const trackingCookies = getTrackingCookies();
      trackingCookies.client_ip = authStore.userIp;

      const userData = await authApi.register({
        first_name: "4C",
        last_name: "Friend",
        email: emailAddress.value,
        password: password.value,
        birth_year: "2000",
        accepted_marketing: true,
        accepted_risk: true,
        accepted_terms: acceptTerms.value,
        cookies: trackingCookies,
        free_trial: false,
      });

      if (userData) {
        pushToDataLayer({
          event: "Register",
          eventCategory: "Register",
          eventAction: "submit",
          eventLabel: "RegisterForm",
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "sign_up_fe",
        });

        authStore.setUser(userData);

        if (authStore.downloadOnAuth) {
          downloadFile(authStore.downloadOnAuth.filePath, authStore.downloadOnAuth.fileName);
          authStore.setDownloadOnAuth(null);
          authStore.setActiveModal(null);
          authStore.setAuthModalVisible(false);
        } else if (!authStore.user?.has_access && authStore.authAndGoPay) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "initiateCheckout",
          });
          authStore.setAuthAndGoPay(false);
          requestPaymentLink();
        } else if (authStore.redirectUrl) {
          const redirect = authStore.redirectUrl;
          window.location.href = redirect;
        } else {
          authStore.setAuthModalVisible(false);
        }
      }
    } catch (error: unknown) {
      const err = error as { response?: { data?: { errors?: unknown } } };
      const errors = err.response?.data?.errors;
      if (errors) {
        if (typeof errors === "string") {
          backendError.value = errors;
        } else if (typeof errors === "object") {
          const firstKey = Object.keys(errors)[0];
          const firstError = Array.isArray(errors[firstKey])
            ? errors[firstKey][0]
            : errors[firstKey];
          backendError.value = firstError;
        } else {
          backendError.value = "Registration failed.";
        }
      } else {
        backendError.value = "Registration failed.";
      }
      authStore.setIsLoading(false);
    }
  }
};
</script>
