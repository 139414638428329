<template>
  <div>
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2680_28526)">
        <path
          d="M7.51399 0.63206C7.41746 0.436387 7.21819 0.3125 7 0.3125C6.78181 0.3125 6.58254 0.436387 6.48601 0.63206L4.63257 4.38884L0.491148 4.9876C0.275233 5.01882 0.0957625 5.16991 0.0281968 5.37734C-0.0393689 5.58477 0.0166879 5.81258 0.172798 5.96497L3.16711 8.88799L2.46127 13.0161C2.42452 13.231 2.51291 13.4482 2.68931 13.5764C2.86571 13.7045 3.09957 13.7215 3.29263 13.6201L7 11.6738L10.7074 13.6201C10.9004 13.7215 11.1343 13.7045 11.3107 13.5764C11.4871 13.4482 11.5755 13.231 11.5387 13.0161L10.8329 8.88799L13.8272 5.96497C13.9833 5.81258 14.0394 5.58477 13.9718 5.37734C13.9042 5.16991 13.7248 5.01882 13.5089 4.9876L9.36743 4.38884L7.51399 0.63206Z"
          fill="#FACC15"
        />
      </g>
      <defs>
        <clipPath id="clip0_2680_28526">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
