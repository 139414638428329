<script setup lang="ts">
import BannerImage from "@/assets/images/landing/baseball/bg.webp";
import BlackCheckIcon from "@/ui/components/icons/BlackCheckIcon.vue";
import GoogleIcon from "@/ui/components/icons/GoogleIcon.vue";
import StarIcon from "@/ui/components/icons/StarIcon.vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import LandingBanner from "../partials/LandingBanner.vue";
import LandingBrands from "../partials/LandingBrands.vue";
import LandingFooter from "../partials/LandingFooter.vue";
import LandingHeader from "../partials/LandingHeader.vue";
import LandingTestimonials from "../partials/LandingTestimonials.vue";

const router = useRouter();
const isMobile = ref(false);

const stats = [
  {
    title: "Built by",
    edge: "350+",
    textColor: "from-lime-100 to-lime-400",
    subTitle: "Elite AI Prediction Analysts.",
  },
  {
    title: "Over",
    edge: "1,2M+",
    textColor: "from-lime-100 to-surface-600",
    subTitle: "Baseball matches analyzed.",
  },
  {
    title: "Accuracy Rate",
    edge: "85%+",
    textColor: "from-lime-100 to-lime-400",
    subTitle: "Real-time AI-driven analysis & deep learning.",
  },
  {
    title: "Prediction Accuracy",
    edge: "78.3%+",
    textColor: "from-lime-100 to-surface-600",
    subTitle: "Average win prediction accuracy across top models.",
  },
];

onMounted(() => {
  isMobile.value = window.innerWidth < 768;
});
</script>

<template>
  <div class="min-h-screen w-full bg-[#020617]">
    <LandingHeader variant="baseball" />
    <main>
      <LandingBanner :bannerImage="BannerImage" variant="baseball">
        <div class="inline-flex items-center justify-start gap-2 self-stretch">
          <div class="flex items-center justify-start gap-1">
            <GoogleIcon class="h-[18px] w-[18px]" />
            <div class="relative h-3.5 w-3.5">
              <StarIcon class="h-[13.37px] w-3.5" />
            </div>
            <div class="relative h-3.5 w-3.5">
              <StarIcon class="h-[13.37px] w-3.5" />
            </div>
            <div class="relative h-3.5 w-3.5">
              <StarIcon class="h-[13.37px] w-3.5" />
            </div>
            <div class="relative h-3.5 w-3.5">
              <StarIcon class="h-[13.37px] w-3.5" />
            </div>
            <div class="relative h-3.5 w-3.5">
              <StarIcon class="h-[13.37px] w-3.5" />
            </div>
          </div>
          <div class="justify-center text-xs font-semibold leading-none text-lime-400">
            94% - Excellence
          </div>
        </div>
        <div
          class="w-full text-[50px] font-medium leading-[50px] text-white md:text-[70px] md:leading-[70px]"
        >
          Accurate <span class="font-extrabold">Baseball</span> Predictions,<br />
          <span class="font-extrabold text-primary">Powered by AI.</span>
        </div>
        <div class="text-white">
          <p>
            <span class="text-primary">Stop Guessing. Start Winning</span>. Our AI-driven forecasts
            let you see the game in a whole new way - before the action unfolds.
          </p>
        </div>
        <div>
          <Button
            @click="router.push({ name: 'register', query: { target: 'baseball' } })"
            class="w-full px-6 md:w-auto"
          >
            <span class="text-nowrap text-[1.2rem] font-bold uppercase text-black md:text-[1.5rem]"
              >Get Your Baseball Picks Now!</span
            >
          </Button>
        </div>
      </LandingBanner>
      <section id="stats" class="bg-white py-2 md:py-6">
        <div class="mx-auto w-full p-4 pb-12 text-center md:max-w-[1312px] md:rounded-md md:pt-0">
          <h2 class="font-semibold">As Featured In:</h2>
          <!-- SLIDER -->
          <LandingBrands />
          <h1 class="text-[50px] leading-[50px] md:text-[70px] md:leading-[70px]">
            <strong>Breaking Down</strong> the
            <strong
              >Top<br />
              Baseball Model</strong
            >
            Performers
          </h1>
          <p class="mt-5 text-lg">
            Get <strong>baseball predictions</strong> from
            <strong>intelligent model builders</strong> competing to be the
            <strong>most accurate</strong>.
          </p>
          <div class="mt-24 md:text-start">
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              <div
                v-for="(item, index) in stats"
                :key="index"
                class="mb-4 flex w-full flex-col gap-2 rounded-xl bg-[#020617] p-5 md:px-8"
              >
                <h2
                  :class="`${item.textColor}`"
                  class="bg-gradient-to-b bg-clip-text text-2xl font-bold text-transparent"
                >
                  {{ item.title }}
                </h2>
                <h1
                  :class="`${item.textColor}`"
                  class="bg-gradient-to-b bg-clip-text text-[60px] font-extrabold text-transparent"
                >
                  {{ item.edge }}
                </h1>
                <p class="text-sm text-white md:text-base">{{ item.subTitle }}</p>
              </div>
            </div>
            <p class="mt-6 text-center">
              <strong>Track</strong> live <strong>accuracy, compare top performers</strong>, and get
              premium predictions from the <strong>highest-ranking AI creators</strong>.
            </p>
            <div class="mt-5 flex justify-center">
              <Button
                @click="router.push({ name: 'register', query: { target: 'baseball' } })"
                class="w-full border-none !bg-[#020617] !px-8 md:w-auto"
              >
                <span
                  class="text-nowrap text-[1.2rem] font-bold uppercase text-white md:text-[1.5rem]"
                  >Get Your Baseball Picks!</span
                >
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="mx-auto w-full p-4 md:max-w-[1312px]">
        <div
          class="inline-flex flex-col items-start justify-center gap-20 self-stretch py-5 md:flex-row md:px-0 md:py-16"
        >
          <div class="inline-flex flex-1 flex-col items-center justify-start gap-4">
            <div class="flex flex-col items-center justify-start gap-4 self-stretch">
              <div
                class="justify-start self-stretch text-center text-[50px] leading-[50px] md:text-start md:text-[70px] md:leading-[70px]"
              >
                <span class="font-medium text-white">Unlock a New Era of </span
                ><span class="font-bold text-lime-400">Accuracy</span
                ><span class="font-medium text-white">, </span
                ><span class="font-bold text-lime-400">Insights</span
                ><span class="font-medium text-white">, and </span
                ><span class="font-bold text-lime-400">Winning </span
                ><span class="font-medium text-lime-400"> </span
                ><span class="font-medium text-white">Predictions.</span>
              </div>
            </div>
            <div class="justify-start self-stretch text-center text-white md:text-start">
              4C Predictions is the ultimate AI prediction marketplace, where top AI creators submit
              their models to compete for the most accurate game predictions. Our platform analyzes
              millions of data points, runs tens of millions of simulations, and delivers real-time
              insights across multiple sports.
            </div>
          </div>
          <div class="flex w-full flex-1 flex-col items-start justify-start gap-8 md:flex-row">
            <div class="inline-flex w-full flex-1 flex-col items-start justify-center gap-8">
              <div
                class="outline-opacity-10 flex h-[250px] w-full flex-col items-start justify-start gap-8 self-stretch rounded-[32px] bg-gradient-to-b from-[#1a1a1a] to-black p-8 outline outline-1 outline-offset-[-1px] outline-white/10"
              >
                <div class="relative h-8 w-8">
                  <div class="absolute left-0 top-0 h-8 w-8">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1_2576)">
                        <path
                          d="M18.4242 2.01314V1.45455C18.4242 0.651636 17.7726 0 16.9697 0C7.61212 0 0 7.61212 0 16.9697C0 20.0301 0.762185 22.9644 2.20315 25.4565L2.20511 25.4545C2.47468 25.92 2.96145 26.1818 3.46375 26.1818C3.71005 26.1818 3.9583 26.1198 4.18715 25.9879L4.74376 25.6679C7.54618 29.5952 12.0979 32 16.9697 32C25.2567 32 32 25.2567 32 16.9697C32 9.17333 26.0325 2.74817 18.4242 2.01314ZM15.5152 2.98473V16.1261L4.08437 22.6909C3.32413 20.9435 2.90909 18.9983 2.90909 16.9678C2.90909 9.70671 8.44218 3.71588 15.5152 2.98473ZM16.9697 29.0909C13.1355 29.0909 9.53988 27.2504 7.26885 24.2192L17.695 18.2322C18.1469 17.9724 18.4242 17.4914 18.4242 16.9717V4.94549C24.4228 5.66694 29.0909 10.7811 29.0909 16.9717C29.0909 23.6529 23.6528 29.0909 16.9697 29.0909Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_2576">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div class="relative min-h-[122px] w-full self-stretch md:w-[228px]">
                  <div
                    class="absolute left-0 top-0 inline-flex w-full max-w-[228px] flex-col items-center justify-start gap-4"
                  >
                    <div
                      class="justify-start self-stretch text-2xl font-medium leading-tight text-lime-400"
                    >
                      AI from the Best Minds
                    </div>
                  </div>
                  <div
                    class="absolute left-0 top-[68px] w-[228px] justify-start text-lg font-normal leading-[27px] text-white"
                  >
                    Experts build & compete with their models.
                  </div>
                </div>
              </div>
              <div
                class="outline-opacity-10 flex h-[250px] w-full flex-col items-start justify-start gap-8 self-stretch rounded-[32px] bg-gradient-to-b from-[#1a1a1a] to-black p-8 outline outline-1 outline-offset-[-1px] outline-white/10"
              >
                <div class="relative h-8 w-8">
                  <div class="absolute left-0 top-[4.10px] h-[23.79px] w-8">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1_2593)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.1282 10.2564C13.1282 13.65 15.8901 16.4102 19.2821 16.4102C22.6741 16.4102 25.4359 13.65 25.4359 10.2564C25.4359 6.86274 22.6741 4.10254 19.2821 4.10254C15.8901 4.10254 13.1282 6.86274 13.1282 10.2564ZM15.5897 10.2564C15.5897 8.21987 17.2472 6.56408 19.2821 6.56408C21.3169 6.56408 22.9744 8.21987 22.9744 10.2564C22.9744 12.2929 21.3169 13.9487 19.2821 13.9487C17.2472 13.9487 15.5897 12.2929 15.5897 10.2564Z"
                          fill="white"
                        />
                        <path
                          d="M29.5385 26.6666C29.5385 27.346 30.0898 27.8974 30.7692 27.8974C31.4486 27.8974 32 27.3477 32 26.6666C32 18.8718 23.0876 18.8718 19.2821 18.8718C15.4765 18.8718 6.5641 18.8718 6.5641 26.6666C6.5641 27.346 7.11549 27.8974 7.79487 27.8974C8.47426 27.8974 9.02564 27.346 9.02564 26.6666C9.02564 23.465 10.7684 21.3333 19.2821 21.3333C27.7941 21.3333 29.5385 23.465 29.5385 26.6666Z"
                          fill="white"
                        />
                        <path
                          d="M9.95592 17.7476C9.79674 17.7476 9.63756 17.7394 9.4751 17.7246C8.16721 17.5999 6.98403 16.9731 6.14874 15.9573C5.31182 14.9431 4.91961 13.6647 5.04433 12.3552C5.16905 11.0457 5.79756 9.86413 6.81171 9.0272C7.82587 8.19028 9.10259 7.80136 10.4121 7.92279C11.0882 7.98843 11.5855 8.58905 11.5215 9.26515C11.4558 9.94289 10.8421 10.4385 10.1791 10.3728C9.52925 10.3105 8.88597 10.5058 8.37889 10.9242C7.87018 11.3427 7.55838 11.9335 7.49602 12.5882C7.43202 13.243 7.62895 13.883 8.04741 14.3901C8.46587 14.8972 9.05664 15.2106 9.71141 15.2729C10.2447 15.3238 10.7764 15.2024 11.2392 14.9185C11.8185 14.5607 12.575 14.7429 12.9311 15.3189C13.2888 15.8982 13.1083 16.6547 12.5307 17.0124C11.7479 17.4982 10.8651 17.7476 9.95592 17.7476Z"
                          fill="white"
                        />
                        <path
                          d="M0 25.4359C0 26.1153 0.551385 26.6666 1.23077 26.6666C1.91015 26.6666 2.46154 26.1169 2.46154 25.4359C2.46154 21.7419 3.11303 20.5128 6.97436 20.5128C7.65375 20.5128 8.20513 19.9614 8.20513 19.282C8.20513 18.6026 7.65375 18.0513 6.97436 18.0513C1.18646 18.0513 0 21.0035 0 25.4359Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_2593">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div class="relative min-h-[122px] w-full">
                  <div
                    class="absolute left-0 top-0 inline-flex w-full flex-col items-center justify-start gap-4"
                  >
                    <div
                      class="justify-start self-stretch text-2xl font-medium leading-tight text-lime-400"
                    >
                      Multi-Sport Coverage
                    </div>
                  </div>
                  <div
                    class="absolute left-0 top-[68px] w-[228px] justify-start text-lg font-normal leading-[27px] text-white"
                  >
                    Featuring soccer, baseball, basketball, and football.
                  </div>
                </div>
              </div>
            </div>
            <div class="inline-flex w-full flex-1 flex-col items-start justify-center gap-8">
              <div
                class="outline-opacity-10 flex h-[250px] w-full flex-col items-start justify-start gap-8 self-stretch rounded-[32px] bg-gradient-to-b from-[#1a1a1a] to-black p-8 outline outline-1 outline-offset-[-1px] outline-white/10"
              >
                <div class="relative h-8 w-8">
                  <div class="absolute left-[2px] top-0 h-8 w-7">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.5414 1.54839C17.5414 0.693236 16.8513 0 16 0C15.1488 0 14.4587 0.693236 14.4587 1.54839V13.3181L4.31225 7.43325C3.57504 7.00568 2.63238 7.25942 2.20676 8C1.78113 8.74058 2.03372 9.68756 2.77092 10.1151L12.9173 16L2.77092 21.8849C2.03372 22.3124 1.78113 23.2594 2.20676 24C2.63238 24.7406 3.57504 24.9943 4.31225 24.5667L14.4587 18.6819V30.4516C14.4587 31.3068 15.1488 32 16 32C16.8513 32 17.5414 31.3068 17.5414 30.4516V18.6819L27.6877 24.5667C28.425 24.9943 29.3676 24.7406 29.7932 24C30.2189 23.2594 29.9663 22.3124 29.2291 21.8849L19.0827 16L29.2291 10.1151C29.9663 9.68756 30.2189 8.74058 29.7932 8C29.3676 7.25942 28.425 7.00568 27.6877 7.43325L17.5414 13.3181V1.54839Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div class="relative min-h-[122px] w-full">
                  <div
                    class="absolute left-0 top-0 inline-flex w-full flex-col items-center justify-start gap-4"
                  >
                    <div
                      class="justify-start self-stretch text-2xl font-medium leading-tight text-lime-400"
                    >
                      Real-Time, Data-Driven Predictions
                    </div>
                  </div>
                  <div
                    class="absolute left-0 top-[68px] w-[228px] justify-start text-lg font-normal leading-[27px] text-white"
                  >
                    No opinions, just AI-powered insights.
                  </div>
                </div>
              </div>
              <div
                class="outline-opacity-10 flex h-[250px] w-full flex-col items-start justify-start gap-8 self-stretch rounded-[32px] bg-gradient-to-b from-[#1a1a1a] to-black p-8 outline outline-1 outline-offset-[-1px] outline-white/10"
              >
                <div class="relative h-8 w-8">
                  <div class="absolute left-0 top-0 h-8 w-8">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1_2627)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16 2.66655C8.63614 2.66655 2.66655 8.63614 2.66655 16C2.66655 23.3639 8.63614 29.3335 16 29.3335C23.3639 29.3335 29.3335 23.3639 29.3335 16C29.3335 8.63614 23.3639 2.66655 16 2.66655ZM0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM15.9993 8.22186C11.7039 8.22186 8.22186 11.7039 8.22186 15.9993C8.22186 20.2947 11.7039 23.7767 15.9993 23.7767C20.2947 23.7767 23.7767 20.2947 23.7767 15.9993C23.7767 11.7039 20.2947 8.22186 15.9993 8.22186ZM5.55531 15.9993C5.55531 10.2312 10.2312 5.55531 15.9993 5.55531C21.7673 5.55531 26.4433 10.2312 26.4433 15.9993C26.4433 21.7673 21.7673 26.4433 15.9993 26.4433C10.2312 26.4433 5.55531 21.7673 5.55531 15.9993ZM13.7772 15.9993C13.7772 14.772 14.772 13.7772 15.9993 13.7772C17.2265 13.7772 18.2214 14.772 18.2214 15.9993C18.2214 17.2265 17.2265 18.2214 15.9993 18.2214C14.772 18.2214 13.7772 17.2265 13.7772 15.9993ZM15.9993 11.1106C13.2994 11.1106 11.1106 13.2994 11.1106 15.9993C11.1106 18.6992 13.2994 20.888 15.9993 20.888C18.6992 20.888 20.888 18.6992 20.888 15.9993C20.888 13.2994 18.6992 11.1106 15.9993 11.1106Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_2627">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div class="relative h-[122px] w-[228px]">
                  <div
                    class="absolute left-0 top-0 inline-flex w-[228px] flex-col items-center justify-start gap-4"
                  >
                    <div
                      class="justify-start self-stretch text-2xl font-medium leading-tight text-lime-400"
                    >
                      Proven Performance
                    </div>
                  </div>
                  <div
                    class="absolute left-0 top-[68px] w-[228px] justify-start text-lg font-normal leading-[27px] text-white"
                  >
                    Models continuously train on the latest data.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="users" class="mx-auto mb-8 w-full p-4 md:max-w-[1312px]">
        <div
          class="inline-flex w-full flex-col items-center justify-center gap-4 self-stretch md:px-16"
        >
          <div class="flex w-full flex-col items-center justify-center gap-4">
            <div
              class="justify-center self-stretch text-center text-[50px] leading-[50px] md:text-[70px] md:leading-[70px]"
            >
              <span class="font-bold leading-[84px] text-lime-400">Real</span
              ><span class="font-medium text-white"> Users, </span
              ><span class="font-bold text-lime-400">Real</span
              ><span class="font-medium text-white"> Results.</span>
            </div>
          </div>
          <div class="justify-start text-center text-white md:max-w-[800px]">
            See what over 50,000 users are saying about 4C Predictions, following the action and
            getting expert Baseball predictions!
          </div>
        </div>

        <LandingTestimonials />
        <div class="mt-16 flex justify-center">
          <Button
            @click="router.push({ name: 'register', query: { target: 'baseball' } })"
            class="w-full px-8 md:w-auto"
          >
            <span class="text-nowrap text-[1.2rem] font-bold uppercase text-black md:text-[1.5rem]"
              >Get Your Baseball Picks Now!</span
            >
          </Button>
        </div>
      </section>
      <section id="predictions" class="bg-white py-6 md:py-12">
        <div class="mx-auto w-full p-4 py-12 text-center md:max-w-[1312px] md:rounded-md">
          <div
            class="justify-start text-center text-[50px] leading-[50px] md:text-[70px] md:leading-[70px]"
          >
            <span class="font-medium text-slate-950">Don't just watch - </span
            ><span class="font-bold text-slate-950">get in the game</span
            ><span class="font-medium text-slate-950">!</span>
          </div>
          <p class="my-5 text-base">
            Get the Major League Baseball Special and join over 50,000 satisfied users gaining an
            edge with real-time insights.
          </p>
          <div class="mt-10 flex flex-col items-center justify-center">
            <div class="w-full max-w-[550px] rounded-[32px] bg-primary p-5 text-start md:p-10">
              <div class="inline-flex flex-col items-start justify-start gap-6 self-stretch">
                <div class="flex flex-col items-start justify-start gap-1 self-stretch">
                  <div
                    class="justify-start self-stretch text-2xl font-semibold leading-[31.20px] text-slate-950"
                  >
                    Major League Baseball Special
                  </div>
                  <div
                    class="justify-start self-stretch text-base font-normal leading-normal text-slate-950"
                  >
                    Real-time analysis and advanced features
                  </div>
                </div>
                <div class="flex flex-col items-start justify-center gap-1 self-stretch">
                  <div class="inline-flex items-center justify-start gap-1">
                    <div class="justify-start text-[40px] font-semibold leading-10 text-slate-950">
                      $19
                    </div>
                    <div class="justify-end text-sm font-normal leading-none text-slate-950">
                      / month
                    </div>
                  </div>
                  <div class="inline-flex items-center justify-start gap-2">
                    <div
                      class="justify-start text-sm font-normal leading-[21px] text-slate-950 line-through"
                    >
                      $199 / month
                    </div>
                    <div
                      class="flex items-center justify-center gap-2 rounded-[100px] bg-slate-950 px-4 py-1"
                    >
                      <div class="justify-start text-xs font-normal leading-[18px] text-lime-400">
                        For the first 777 Customers.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col items-start justify-start gap-2">
                  <div class="inline-flex h-6 items-start justify-start gap-1">
                    <div class="flex items-center justify-center gap-2.5 p-1">
                      <div class="relative h-4 w-4 overflow-hidden">
                        <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
                      </div>
                    </div>
                    <div
                      class="flex-1 justify-start text-base font-semibold leading-normal text-slate-950"
                    >
                      Unlimited Sports Predictions.
                    </div>
                  </div>
                  <div class="inline-flex h-6 items-start justify-start gap-1">
                    <div class="flex items-center justify-center gap-2.5 p-1">
                      <div class="relative h-4 w-4 overflow-hidden">
                        <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
                      </div>
                    </div>
                    <div class="flex-1 justify-start">
                      <span class="text-base font-semibold leading-normal text-slate-950"
                        >Every </span
                      ><span class="text-base font-normal leading-normal text-slate-950">Sport</span
                      ><span class="text-base font-semibold leading-normal text-slate-950"
                        >, Every </span
                      ><span class="text-base font-normal leading-normal text-slate-950"
                        >Prediction</span
                      ><span class="text-base font-semibold leading-normal text-slate-950">.</span>
                    </div>
                  </div>
                  <div class="inline-flex h-6 items-start justify-start gap-1">
                    <div class="flex items-center justify-center gap-2.5 p-1">
                      <div class="relative h-4 w-4 overflow-hidden">
                        <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
                      </div>
                    </div>
                    <div class="flex-1 justify-start">
                      <span class="text-base font-semibold leading-normal text-slate-950"
                        >Instant Picks</span
                      ><span class="text-base font-normal leading-normal text-slate-950"
                        >, No Waiting.</span
                      >
                    </div>
                  </div>
                  <div class="inline-flex h-6 items-start justify-start gap-1">
                    <div class="flex items-center justify-center gap-2.5 p-1">
                      <div class="relative h-4 w-4 overflow-hidden">
                        <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
                      </div>
                    </div>
                    <div class="flex-1 justify-start">
                      <span class="text-base font-normal leading-normal text-slate-950"
                        >Get the Edge </span
                      ><span class="text-base font-semibold leading-normal text-slate-950"
                        >Sports Books Fear.</span
                      >
                    </div>
                  </div>
                  <div class="inline-flex h-6 items-start justify-start gap-1">
                    <div class="flex items-center justify-center gap-2.5 p-1">
                      <div class="relative h-4 w-4 overflow-hidden">
                        <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
                      </div>
                    </div>
                    <div class="flex-1 justify-start">
                      <span class="text-base font-semibold leading-normal text-slate-950"
                        >Sharp Bettors </span
                      ><span class="text-base font-normal leading-normal text-slate-950"
                        >Use AI.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <Button
                @click="router.push({ name: 'register', query: { target: 'baseball' } })"
                class="mt-8 w-full !rounded-[12px] border-none bg-[#020617] !py-3 px-8"
              >
                <span
                  class="text-nowrap text-[1.2rem] font-bold uppercase text-white md:text-[1.5rem]"
                  >Get Predictions Now!</span
                >
              </Button>
              <div
                class="mt-5 justify-start self-stretch text-center text-base font-normal leading-normal text-slate-950"
              >
                Billed Monthly | Cancel Anytime
              </div>
            </div>
          </div>
        </div>
      </section>
      <LandingFooter />
    </main>
  </div>
</template>
