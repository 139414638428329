<template>
  <!-- <header
    class=" top-0 z-50 flex flex-col border-b border-lime-400 bg-zinc-950 p-4 shadow-md transition-all duration-300"
  > -->
  <header :class="headerClasses">
    <div class="mx-auto flex w-full items-start justify-between gap-4 pb-2 md:pb-2" :class="{ '!pb-0': isScrolled }">
      <div class="mb-0 flex flex-1 items-center md:mb-2">
        <router-link to="/">
          <SiteLogo />
        </router-link>
      </div>
      <div class="mx-4 hidden flex-1 md:block">
        <MainSearch />
      </div>
      <div class="flex flex-1 items-center justify-end space-x-2">
        <Button v-if="!user" @click="openAuthModal('login')" label="Login"
          class="p-button-text h-[32px] w-[70px] rounded-md border border-zinc-500 text-[14px] !text-white hover:bg-transparent md:h-[33px] md:w-auto" />
        <Button v-if="!user" @click="openAuthModal('join')" label="Join"
          class="h-[32px] w-[70px] rounded-md text-[14px] md:h-[33px] md:w-auto" />
        <Select v-if="user" :options="userOptions" v-model="selectedNewOption" optionLabel="label" append-to="self"
          class="p-button-text user-profile rounded-full border-[3px] border-transparent !bg-transparent !p-0 pl-0 shadow-none hover:bg-transparent">
          <template #value="">
            <div class="flex items-center gap-2">
              <div>
                <Avatar v-if="user.avatar" :image="user.avatar" class="bg-primary p-[1px] md:h-[42px] md:w-[42px]"
                  shape="circle" />
                <Avatar v-if="!user.avatar" :label="`${user.email[0].toUpperCase()}`"
                  class="border border-primary bg-transparent text-xs font-semibold text-white md:h-[42px] md:w-[42px] md:text-sm"
                  shape="circle" />
              </div>
              <!--              <div class="hidden text-sm leading-none text-white md:block">-->
              <!--                {{ user?.first_name }} {{ user?.last_name }}-->
              <!--              </div>-->
            </div>
          </template>
          <template #header>
            <div class="flex min-w-[320px] flex-col gap-3 border-b p-5">
              <div class="flex items-center gap-2">
                <div>
                  <Avatar v-if="user.avatar" :image="user.avatar" class="bg-primary p-[1px] md:h-[42px] md:w-[42px]"
                    shape="circle" />
                  <Avatar v-if="!user.avatar" :label="`${user.email[0].toUpperCase()}`"
                    class="border border-slate-500 bg-transparent text-xs font-semibold text-slate-700 md:h-[42px] md:w-[42px] md:text-sm"
                    shape="circle" />
                </div>
                <div class="flex flex-col items-start justify-start text-sm leading-5 text-slate-500">
                  <!--                  <span class="text-slate-700">{{ user?.first_name }} {{ user?.last_name }}</span>-->
                  <span class="text-xs">{{ user?.email }}</span>
                </div>
              </div>
            </div>
          </template>
          <template #option="slotProps">
            <div class="flex items-center px-2">
              <span :class="`text-[15px] font-bold text-${slotProps.option.color}-500`">{{
                slotProps.option.label
              }}</span>
            </div>
          </template>
        </Select>
      </div>
    </div>
    <div>
      <HeaderNav />
    </div>
  </header>
  <AuthModal v-model:visible="authStore.authModalVisible" />
</template>

<script lang="ts" setup>
import { authApi } from "@/api/authApi";
import { useAuthStore } from "@/stores/authStore";
import type { User } from "@/types/user";
import SiteLogo from "@/ui/components/SiteLogo.vue";
import HeaderNav from "@/ui/layouts/HeaderNav.vue";
import { computed, ref, watch } from "vue";
// import { useRoute, useRouter } from "vue-router";
import { useRoute } from "vue-router";
import AuthModal from "../components/auth/AuthModal.vue";
import MainSearch from "./MainSearch.vue";
import router from "@/router";

interface UserOption {
  label: string;
  icon?: unknown;
  color?: string;
  action?: () => void;
}

interface NewOption {
  label: string;
  action: () => void;
}

const authStore = useAuthStore();
// const router = useRouter();
const route = useRoute();

const user = ref<User | null>(null);

const isScrolled = ref(false);
const selectedNewOption = ref<NewOption | null>(null);

// console.log(route.name);

const headerClasses = computed(() => {
  console.log(route.name);
  return route.name === "single-event"
    ? "top-0 z-50 flex flex-col border-b border-lime-400 bg-zinc-950 p-4 shadow-md transition-all duration-300" // non-sticky version for this page
    : "sticky top-0 z-50 flex flex-col border-b border-lime-400 bg-zinc-950 p-4 shadow-md transition-all duration-300"; // sticky on other pages
});

const logout = async () => {
  await authApi.logout();
};

watch(selectedNewOption, (newValue) => {
  if (newValue && newValue.action) {
    newValue.action();
    selectedNewOption.value = null;
  }
});

watch(
  () => authStore.user,
  () => {
    user.value = authStore.user;
  },
  { immediate: true },
);

const goToProfile = () => {
  router.push({ name: "profile" });
};

const userOptions = ref<UserOption[]>([
  { label: "Profile", icon: null, action: goToProfile, color: "gray" },
  { label: "Log out", icon: null, action: logout, color: "rose" },
]);

const openAuthModal = (variant: string): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "header_join_btn",
  });
  authStore.setActiveModal(variant);
  authStore.setAuthModalVisible(true);
};
</script>
