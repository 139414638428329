<script setup lang="ts">
import FullLogo from "@/assets/images/full-logo.webp";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import BuilderNavigation from "./BuilderNavigation.vue";

const router = useRouter();
const mobileOpen = ref(false);
const scrolled = ref(false);

const handleScroll = () => {
  scrolled.value = window.scrollY > 10;
};

const closeMobile = () => {
  if (mobileOpen.value) {
    mobileOpen.value = false;
  }
};

const toggleMobile = () => {
  mobileOpen.value = !mobileOpen.value;
};

onMounted(() => {
  handleScroll();
  window.addEventListener("scroll", handleScroll);
});
</script>

<template>
  <header class="fixed z-50 h-[80px] w-full bg-[#020617]">
    <div
      class="mx-auto flex h-full w-full max-w-[1312px] items-center justify-between gap-5 px-6 text-white md:px-0"
    >
      <router-link :to="{ name: 'home' }"><img :src="FullLogo" class="h-[44px]" /></router-link>
      <nav class="hidden flex-1 items-center justify-center gap-8 md:flex">
        <BuilderNavigation />
      </nav>
      <Button @click="router.push({ name: 'creator.join' })" class="hidden !px-6 md:block">
        <span class="text-nowrap font-bold text-black">Join Now</span>
      </Button>
      <Button
        @click="toggleMobile"
        class="block border-none bg-transparent p-0 hover:bg-transparent md:hidden"
      >
        <i :class="`pi pi-${mobileOpen ? 'times' : 'bars'} text-xl text-white`"></i>
      </Button>
    </div>
  </header>
  <nav
    v-if="mobileOpen"
    class="fixed left-0 top-[80px] z-20 flex h-[calc(100%-80px)] w-full flex-col items-start gap-5 bg-[#020617] px-6 py-4 md:hidden"
  >
    <BuilderNavigation @clicked="closeMobile" />
    <Button @click="router.push({ name: 'creator.join' })" class="w-full">
      <span class="text-nowrap font-bold text-black">Join Now</span>
    </Button>
  </nav>
</template>
