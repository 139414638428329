<script setup lang="ts">
import { authApi } from "@/api/authApi";
import { useCookieTracking } from "@/composables/useCookieTracking";
import { useDataLayer } from "@/composables/useDataLayer";
import { useAuthStore } from "@/stores/authStore";
import ImageUploader from "@/ui/components/ImageUploader.vue";
import fileHelper from "@/utils/fileHelper";
import useVuelidate from "@vuelidate/core";
import { email, required, url } from "@vuelidate/validators";
import { Textarea } from "primevue";
import { computed, ref } from "vue";
import BuilderFooter from "./partials/BuilderFooter.vue";
import BuilderHeader from "./partials/BuilderHeader.vue";

const { getTrackingCookies } = useCookieTracking();
const { pushToDataLayer } = useDataLayer();
const authStore = useAuthStore();
const step = ref(1);
const loading = ref(false);

const first_name = ref(authStore.user?.first_name || "");
const last_name = ref(authStore.user?.last_name || "");
const emailAddress = ref(authStore.user?.email || "");
const password = ref("");
const mobile_number = ref("");
const linkedin = ref("");

const bio = ref("");
const avatar = ref("");

const degree = ref("");
const coursework = ref("");
const publications = ref("");
const projects = ref("");
const experience = ref(null);
const programming_languages = ref("");
const additional_info = ref("");

const backendError = ref("");

const step1Valid = computed(() => {
  const requiresPassword = !!authStore.user;

  return (
    !!first_name.value &&
    !!last_name.value &&
    !!emailAddress.value &&
    !!mobile_number.value &&
    !!linkedin.value &&
    (requiresPassword || !!password.value)
  );
});

const step2Valid = computed(() => {
  return (
    degree.value &&
    coursework.value &&
    publications.value &&
    projects.value &&
    experience.value &&
    programming_languages.value
  );
});

const step3Valid = computed(() => {
  return bio.value && avatar.value;
});

const rules = computed(() => ({
  first_name: { required },
  last_name: { required },
  emailAddress: { required, email },
  password: authStore.user ? {} : { required },
  mobile_number: { required },
  linkedin: { required, url },
  avatar: { required },
  bio: { required },
  degree: { required },
  coursework: { required },
  publications: { required },
  projects: { required },
  experience: { required },
  programming_languages: { required },
  additional_info: {},
}));

const v$ = useVuelidate(rules, {
  first_name,
  last_name,
  emailAddress,
  password,
  mobile_number,
  linkedin,
  avatar,
  bio,
  degree,
  coursework,
  publications,
  projects,
  experience,
  programming_languages,
  additional_info,
});

const validateStep1 = () => {
  if (step1Valid.value) {
    step.value = 2;
  }
};

const validateStep2 = () => {
  if (step2Valid.value) {
    step.value = 3;
  }
};

const register = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect) {
    loading.value = true;
    try {
      const trackingCookies = getTrackingCookies();
      trackingCookies.client_ip = authStore.userIp;

      const blob = fileHelper.base64ToBlob(avatar.value);
      const file = new File([blob], "avatar.webp", { type: "image/webp" });

      const formData = new FormData();

      formData.append("first_name", first_name.value);
      formData.append("last_name", last_name.value);
      formData.append("email", emailAddress.value);
      formData.append("password", password.value);
      formData.append("mobile_number", mobile_number.value);
      formData.append("linkedin", linkedin.value);

      formData.append("bio", bio.value);
      formData.append("avatar", file);

      formData.append("degree", degree.value);
      formData.append("coursework", coursework.value);
      formData.append("publications", publications.value);
      formData.append("projects", projects.value);
      formData.append("experience", experience.value);
      formData.append("programming_languages", programming_languages.value);
      formData.append("additional_info", additional_info.value);

      Object.entries(trackingCookies).forEach(([key, value]) => {
        formData.append(`cookies[${key}]`, value);
      });

      const builder = await authApi.registerBuilder(formData);

      if (builder) {
        pushToDataLayer({
          event: "Register",
          eventCategory: "Register",
          eventAction: "submit",
          eventLabel: "RegisterForm",
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "model_builder_sign_up_fe",
        });

        if (builder.status === "active") {
          // ToDo: Redirect to builder login page - Ask Deon why not use the normal login form?
        }

        step.value = 4;
      }
    } catch (error: unknown) {
      const err = error as { response?: { data?: { errors?: unknown } } };
      console.log("Error:", err);
      const errors = err.response?.data?.errors;
      if (errors) {
        if (typeof errors === "string") {
          backendError.value = errors;
        } else if (typeof errors === "object") {
          const firstKey = Object.keys(errors)[0];
          const firstError = Array.isArray(errors[firstKey])
            ? errors[firstKey][0]
            : errors[firstKey];
          backendError.value = firstError;
        } else {
          backendError.value = "Registration failed.";
        }
      } else {
        backendError.value = "Registration failed.";
      }
      loading.value = false;
    }
  }
};
</script>

<template>
  <div class="min-w-screen min-h-screen bg-[#020617]">
    <BuilderHeader />
    <section class="px-4 md:px-0">
      <div class="mx-auto md:max-w-[1312px] md:py-[120px]">
        <h1
          class="text-center text-[40px] leading-[40px] text-white md:text-[60px] md:leading-[60px]"
        >
          <span class="text-primary">Become</span> a Model Builder
        </h1>
        <p class="py-10 text-center text-white">
          Get your model in the spotlight! Join the top AI platform powering predictions across
          industries!
        </p>
        <div
          v-if="step == 4"
          class="mx-auto w-full max-w-[800px] rounded-[24px] border border-[#fff3] p-[2rem] py-[4rem] text-white"
        >
          <h1 class="mb-5 text-xl text-primary">Thank you!</h1>
          <p>Your application has been successfully submitted and is now under review!</p>
          <p>
            One of our agents will be in touch with you once your application has been reviewed.
          </p>
        </div>
        <form
          v-else
          @submit.prevent="register"
          class="mx-auto w-full max-w-[800px] rounded-[24px] border border-[#fff3] p-6 text-white md:p-[2rem]"
        >
          <h2 class="text-[32px] leading-[32px] text-white">
            <span class="text-primary">{{ step == 2 ? "Profile" : "Personal" }}</span>
            {{ step == 3 ? "Questions" : "Information" }}
          </h2>
          <p class="my-2">Step {{ step }} of 3</p>

          <div v-if="step == 1" class="mt-7 flex flex-col gap-8">
            <div class="grid grid-cols-1 gap-[24px] sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
                <div
                  class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
                >
                  <div class="self-stretch leading-none text-[#ffffffb3]">First Name *</div>
                  <InputText
                    v-model="first_name"
                    @blur="v$.first_name.$touch()"
                    type="text"
                    placeholder="Enter your first name"
                    class="h-[42px] w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                  />
                  <div
                    v-if="v$.first_name.$dirty && v$.first_name.$invalid"
                    class="p-error text-xs text-red-600"
                  >
                    <div v-if="(v$.first_name.required as any)?.$invalid">
                      First name is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
                <div
                  class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
                >
                  <div class="self-stretch leading-none text-[#ffffffb3]">Last Name *</div>
                  <InputText
                    v-model="last_name"
                    @blur="v$.last_name.$touch()"
                    type="text"
                    placeholder="Enter your last name"
                    class="h-[42px] w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                  />
                  <div
                    v-if="v$.last_name.$dirty && v$.last_name.$invalid"
                    class="p-error text-xs text-red-600"
                  >
                    <div v-if="(v$.last_name.required as any)?.$invalid">
                      Last name is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="{
                'grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1': authStore.user,
                'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2': !authStore.user,
              }"
              class="grid gap-[24px]"
            >
              <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
                <div
                  class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
                >
                  <div class="self-stretch leading-none text-[#ffffffb3]">Email Address *</div>
                  <InputText
                    v-model="emailAddress"
                    @blur="v$.emailAddress.$touch()"
                    type="email"
                    placeholder="Enter your email address"
                    class="h-[42px] w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                  />
                  <div
                    v-if="v$.emailAddress.$dirty && v$.emailAddress.$invalid"
                    class="p-error text-xs text-red-600"
                  >
                    <div v-if="(v$.emailAddress.required as any)?.$invalid">Email is required.</div>
                    <div v-else-if="(v$.emailAddress.email as any)?.$invalid">
                      Please enter a valid email address.
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!authStore.user"
                class="inline-flex w-full items-start justify-start gap-2.5 self-stretch"
              >
                <div
                  class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
                >
                  <div class="self-stretch leading-none text-[#ffffffb3]">Password *</div>
                  <Password
                    v-model="password"
                    @blur="v$.password.$touch()"
                    toggleMask
                    :feedback="false"
                    placeholder="Choose your password"
                    class="h-[42px] w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                  />
                  <div
                    v-if="v$.password.$dirty && v$.password.$invalid"
                    class="p-error text-xs text-red-600"
                  >
                    <div v-if="(v$.password.required as any)?.$invalid">Password is required.</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 gap-[24px] sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
                <div
                  class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
                >
                  <div class="self-stretch leading-none text-[#ffffffb3]">Mobile number *</div>
                  <InputText
                    v-model="mobile_number"
                    @blur="v$.mobile_number.$touch()"
                    type="text"
                    placeholder="Enter your mobile number"
                    class="h-[42px] w-full border border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                  />
                  <div
                    v-if="v$.mobile_number.$dirty && v$.mobile_number.$invalid"
                    class="p-error text-xs text-red-600"
                  >
                    <div v-if="(v$.mobile_number.required as any)?.$invalid">
                      Mobile number is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
                <div
                  class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
                >
                  <div class="self-stretch leading-none text-[#ffffffb3]">Linkedin Profile *</div>
                  <InputText
                    v-model="linkedin"
                    @blur="v$.linkedin.$touch()"
                    type="text"
                    placeholder="https://"
                    class="h-[42px] w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                  />
                  <div
                    v-if="v$.linkedin.$dirty && v$.linkedin.$invalid"
                    class="p-error text-xs text-red-600"
                  >
                    <div v-if="(v$.linkedin.required as any)?.$invalid">Linkedin is required.</div>
                    <div v-else-if="(v$.linkedin.url as any)?.$invalid">
                      Please enter a valid linkedin link.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="step == 2" class="mt-7 flex flex-col gap-6">
            <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
              <div
                class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch leading-none text-[#ffffffb3]">
                  Which degrees have you completed, and in which fields? *
                </div>
                <Textarea
                  rows="3"
                  v-model="degree"
                  @blur="v$.degree.$touch()"
                  :class="{ 'p-invalid': v$.degree.$invalid && v$.degree.$dirty }"
                  type="text"
                  placeholder="Provide details here...or put none if appropriate..."
                  class="w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                />
                <div
                  v-if="v$.degree.$dirty && v$.degree.$invalid"
                  class="p-error text-xs text-red-600"
                >
                  <div v-if="(v$.degree.required as any)?.$invalid">Degree is required.</div>
                </div>
              </div>
            </div>
            <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
              <div
                class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch leading-none text-[#ffffffb3]">
                  What relevant coursework have you completed in areas like computer science,
                  mathematics, or data science? *
                </div>
                <Textarea
                  rows="3"
                  v-model="coursework"
                  @blur="v$.coursework.$touch()"
                  :class="{ 'p-invalid': v$.coursework.$invalid && v$.coursework.$dirty }"
                  type="text"
                  placeholder="Provide details here...or put none if appropriate..."
                  class="w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                />
                <div
                  v-if="v$.coursework.$dirty && v$.coursework.$invalid"
                  class="p-error text-xs text-red-600"
                >
                  <div v-if="(v$.coursework.required as any)?.$invalid">
                    Coursework is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
              <div
                class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch leading-none text-[#ffffffb3]">
                  Do you have any publications? *
                </div>
                <Textarea
                  rows="3"
                  v-model="publications"
                  @blur="v$.publications.$touch()"
                  :class="{ 'p-invalid': v$.publications.$invalid && v$.publications.$dirty }"
                  type="text"
                  placeholder="Please specify if so...or put none if appropriate..."
                  class="w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                />
                <div
                  v-if="v$.publications.$dirty && v$.publications.$invalid"
                  class="p-error text-xs text-red-600"
                >
                  <div v-if="(v$.publications.required as any)?.$invalid">
                    Publications is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
              <div
                class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch leading-none text-[#ffffffb3]">
                  Can you provide examples of AI projects you have worked on and the models you
                  developed? *
                </div>
                <Textarea
                  rows="3"
                  v-model="projects"
                  @blur="v$.projects.$touch()"
                  :class="{ 'p-invalid': v$.projects.$invalid && v$.projects.$dirty }"
                  type="text"
                  placeholder="Please provide examples here..."
                  class="w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                />
                <div
                  v-if="v$.projects.$dirty && v$.projects.$invalid"
                  class="p-error text-xs text-red-600"
                >
                  <div v-if="(v$.projects.required as any)?.$invalid">Projects is required.</div>
                </div>
              </div>
            </div>
            <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
              <div
                class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch leading-none text-[#ffffffb3]">
                  Have you had any prior experience in model building? *
                </div>
                <div class="flex flex-col gap-2">
                  <div class="flex cursor-pointer items-center gap-2">
                    <RadioButton
                      v-model="experience"
                      inputId="experience1"
                      name="experience"
                      value="yes"
                      variant="filled"
                    />
                    <label for="experience1" class="cursor-pointer">Yes</label>
                  </div>
                  <div class="flex cursor-pointer items-center gap-2">
                    <RadioButton
                      v-model="experience"
                      inputId="experience2"
                      name="experience"
                      value="no"
                      variant="filled"
                    />
                    <label for="experience2" class="cursor-pointer">No</label>
                  </div>
                </div>
                <div
                  v-if="v$.experience.$dirty && v$.experience.$invalid"
                  class="p-error text-xs text-red-600"
                >
                  <div v-if="(v$.experience.required as any)?.$invalid">
                    Experience is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
              <div
                class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch leading-none text-[#ffffffb3]">
                  What is/are your primary programming language(s)? (Please Specify) *
                </div>
                <Textarea
                  rows="3"
                  v-model="programming_languages"
                  @blur="v$.programming_languages.$touch()"
                  :class="{
                    'p-invalid':
                      v$.programming_languages.$invalid && v$.programming_languages.$dirty,
                  }"
                  type="text"
                  placeholder="Provide details here..."
                  class="w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                />
                <div
                  v-if="v$.programming_languages.$dirty && v$.programming_languages.$invalid"
                  class="p-error text-xs text-red-600"
                >
                  <div v-if="(v$.programming_languages.required as any)?.$invalid">
                    This field is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
              <div
                class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch leading-none text-[#ffffffb3]">
                  Please provide any additional details that you want us to know in considering your
                  application. You may wish to share with us any relevant links to better understand
                  your work.
                </div>
                <Textarea
                  rows="3"
                  v-model="additional_info"
                  @blur="v$.additional_info.$touch()"
                  :class="{ 'p-invalid': v$.additional_info.$invalid && v$.additional_info.$dirty }"
                  type="text"
                  placeholder="Provide details here..."
                  class="w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                />
              </div>
            </div>
          </div>

          <div v-if="step == 3" class="mt-7 flex flex-col gap-8">
            <ImageUploader v-model:avatar="avatar" />
            <div class="inline-flex w-full items-start justify-start gap-2.5 self-stretch">
              <div
                class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch leading-none text-[#ffffffb3]">Bio *</div>
                <Textarea
                  rows="3"
                  v-model="bio"
                  @blur="v$.bio.$touch()"
                  :class="{ 'p-invalid': v$.bio.$invalid && v$.bio.$dirty }"
                  type="text"
                  placeholder="Provide a brief summary about yourself (around 100 words)..."
                  class="w-full border-[#fff3] bg-transparent px-[10.50px] py-[10px] text-sm font-normal text-white shadow-none"
                />
                <div v-if="v$.bio.$dirty && v$.bio.$invalid" class="p-error text-xs text-red-600">
                  <div v-if="(v$.bio.required as any)?.$invalid">Bio is required.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-10">
            <div v-if="backendError" class="mb-2 text-xs text-red-600">
              {{ backendError }}
            </div>
            <Button
              type="button"
              v-if="step == 1"
              :disabled="!step1Valid"
              @click="validateStep1"
              class="w-full"
            >
              <span class="text-nowrap font-bold text-black">Continue</span>
            </Button>
            <div v-else-if="step == 2" class="flex items-center justify-between gap-4">
              <Button
                @click="step = 1"
                :disabled="loading"
                class="flex-1 border border-primary bg-transparent"
              >
                <span class="text-nowrap font-bold text-primary">Back</span>
              </Button>
              <Button
                @click="validateStep2"
                :disabled="!step2Valid || loading"
                type="button"
                class="flex-1"
              >
                <span class="text-nowrap font-bold text-black">Next</span>
              </Button>
            </div>
            <div v-else class="flex items-center justify-between gap-4">
              <Button
                @click="step = 2"
                :disabled="loading"
                class="flex-1 border border-primary bg-transparent"
              >
                <span class="text-nowrap font-bold text-primary">Back</span>
              </Button>
              <Button :disabled="!step3Valid || loading" type="submit" class="flex-1">
                <i v-if="loading" class="pi pi-spin pi-spinner"></i>
                <span class="text-nowrap font-bold text-black">Submit</span>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </section>
    <BuilderFooter />
  </div>
</template>
