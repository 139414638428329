<script setup lang="ts">
import PlainLayout from "@/ui/layouts/PlainLayout.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const error = route.query.error === 'true';

</script>

<template>
  <PlainLayout>
    <div v-if="error" className="max-w-4xl mx-auto px-6 py-4 md:py-12">
      <div class="text-center">
        <h1 class="mb-6 text-center text-3xl font-bold">Something went wrong</h1>
        <p class="text-gray-600">
          There was an issue canceling your subscription.
        </p>
        <p class="mt-4 text-gray-600">
          Please try again.
        </p>
        <p class="mt-4 text-gray-600">
          If the error continues, don’t worry — just email us at
          <a href="mailto:devteam@4cpredictions.ai" class="font-semibold text-sky-500 hover:underline">
            devteam@4cpredictions.ai</a>
            , and we’ll make sure everything is sorted for you.
            <strong>Any accidental charges after this point will be fully refunded.</strong>
        </p>
      </div>
    </div>
    <div v-else-if="route.query.subscriptionType === 'new'" className="max-w-4xl mx-auto px-6 py-4 md:py-12">
      <div class="text-center">
        <h1 class="mb-6 text-center text-3xl font-bold">Subscription Cancellation Confirmed</h1>
        <p class="text-gray-600">
          Your subscription has been successfully cancelled.
        </p>
        <p class="mt-4 text-gray-600">
          You <strong>won't be charged again.</strong>
        </p>
        <p class="mt-4 text-gray-600">
          We’d love to welcome you back anytime. Until then, thank you for trying 4C Predictions.
        </p>
      </div>
    </div>
    <div v-else className="max-w-4xl mx-auto px-6 py-4 md:py-12">
      <div class="text-center">
        <h1 class="mb-6 text-center text-3xl font-bold">Subscription Cancellation Request</h1>
        <p class="text-gray-600">
          Thank you for reaching out — your request has been safely received and is now in the
          hands of our team. We'll review everything and get back to you shortly.
        </p>
        <p class="mt-4 text-gray-600">
          In the meantime, if you have any questions or need help, we're just an email away at
          <a href="mailto:devteam@4cpredictions.ai" class="font-semibold text-sky-500 hover:underline">
            devteam@4cpredictions.ai</a>.
        </p>
        <p class="mt-4 text-gray-600">
          Please rest assured: once you've submitted your request to unsubscribe, you won’t be
          charged again. If there's ever a system hiccup and you are charged by mistake, we'll
          refund you immediately — no questions asked.
        </p>
        <p class="mt-4 font-medium text-gray-700">
          We appreciate you being part of the
          <span class="text-sky-600">4C Predictions</span> community!
        </p>
      </div>
    </div>
  </PlainLayout>
</template>
