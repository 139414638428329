<template>
  <GridLayout>
    <template #leftSidebar>
      <ModelLeftSidebar />
    </template>
    <ModelsMobileCategories />
    <div class="p-2 pb-8 md:p-4">
      <h1 class="mb-2 text-[18px] font-semibold text-slate-700 md:text-[22px]">All AI Models</h1>

      <div class="mb-3 flex w-full items-center justify-between gap-4">
        <div
          class="flex h-[33px] flex-1 items-center rounded-md border border-slate-300 bg-white px-3"
        >
          <SvgIcon name="search" class="h-3.5 w-3.5" />
          <InputText
            placeholder="Search models..."
            v-model="searchQuery"
            @input="debounceSearch"
            class="border-0 bg-transparent h-[31px] text-sm font-medium text-slate-700 !shadow-none"
          />
        </div>
        <div class="relative flex h-[33px] items-center gap-2">
          <span class="text-xs font-medium text-slate-700">Sort by:</span>
          <Select
            v-model="selectedSortFilter"
            :options="displaySortFilters"
            optionLabel="name"
            @change="filterChanged"
            optionValue="code"
            class="h-[33px] min-w-[100px] border border-slate-300 bg-transparent text-sm"
          />
        </div>
      </div>
      <div v-if="!modelStore.isLoading && models.length > 0" class="flex flex-col gap-3">
        <ModelCard v-for="(model, index) in models" :model="model" :key="index" />
      </div>
      <div v-if="modelStore.isLoading" class="flex flex-col gap-3">
        <ModelCardSkeleton v-for="index in 8" :key="index" />
      </div>
      <div
        v-if="!modelStore.isLoading && models.length === 0"
        class="flex items-center justify-center rounded-md border border-yellow-200 bg-yellow-50 p-3"
      >
        <SvgIcon name="info" class="mr-2" />
        <span class="text-sm font-medium text-yellow-600">
          No models found
          <span v-if="type"
            >under
            <strong
              >{{ type }} <span v-if="subType">→ {{ subType }}</span></strong
            ></span
          >
        </span>
      </div>

      <div v-if="hasMore && models.length > 0" class="my-4 flex items-center justify-center">
        <Button
          @click="loadMore"
          :disabled="modelStore.isLoading"
          class="rounded-md border bg-lime-400 px-4 py-2 text-sm"
        >
          <i v-if="modelStore.isLoading" class="pi pi-spin pi-spinner"></i> Show More
        </Button>
      </div>
    </div>
    <template #rightSidebar>
      <EventUpcoming />
    </template>
  </GridLayout>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/authStore";
import { useModelStore } from "@/stores/modelStore";
import type { Model } from "@/types/model";
import { useDebounceFn } from "@vueuse/core";
import { computed, inject, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import EventUpcoming from "@/ui/components/events/EventUpcoming.vue";
import ModelCard from "@/ui/components/models/ModelCard.vue";
import ModelCardSkeleton from "@/ui/components/models/ModelCardSkeleton.vue";
import ModelLeftSidebar from "@/ui/components/models/ModelLeftSidebar.vue";
import ModelsMobileCategories from "@/ui/components/models/ModelsMobileCategories.vue";
import SvgIcon from "@/ui/components/SvgIcon.vue";
import GridLayout from "@/ui/layouts/GridLayout.vue";
import { showGlobalToast } from "@/utils/toast";

const route = useRoute();
const router = useRouter();
const updateMeta = inject("updateMeta") as (meta) => void;
const authStore = useAuthStore();
const modelStore = useModelStore();

const selectedFilter = ref((route.query.filter as string) || "all");
const selectedSortFilter = ref((route.query.sort as string) || "most_accurate");

const searchQuery = ref((route.query.q as string) || "");
const type = ref((route.params.type as string) || "");
const subType = ref("");
const models = ref<Model[]>([]);
const page = ref(1);
const hasMore = ref(false);
const pageSize = 16;
const builderId = ref("");

const filters = ref([
  { name: "All AI Models", code: "all" },
  { name: "Premium AI Models", code: "paid" },
  { name: "Free AI Models", code: "free" },
]);

const sortFilters = ref([
  { name: "Most Accurate", code: "most_accurate" },
  { name: "Least Accurate", code: "least_accurate" },
  { name: "Recently Updated", code: "recently_updated" },
  //   { name: "Most Expensive", code: "most_expensive" },
  //   { name: "Least Expensive", code: "least_expensive" },
]);

const displaySortFilters = computed(() =>
  selectedFilter.value === "free"
    ? sortFilters.value.filter((item) => !["most_expensive", "least_expensive"].includes(item.code))
    : sortFilters.value,
);

const fetchItems = async (retry = true) => {
  try {
    const result = await modelStore.fetchModels({
      q: searchQuery.value,
      type: type.value,
      sub_type: subType.value,
      per_page: pageSize.toString(),
      page: page.value.toString(),
      sort: selectedSortFilter.value,
      filter: selectedFilter.value,
      builder_id: builderId.value,
    });

    // If no models are returned and retry is enabled, refresh and try again
    if (result.models.length === 0 && retry) {
      console.warn("No models returned, refreshing store and retrying...");

      if (typeof modelStore.refreshModels === "function") {
        await modelStore.refreshModels({
          q: searchQuery.value,
          type: type.value,
          sub_type: subType.value,
          per_page: pageSize.toString(),
          page: page.value.toString(),
          sort: selectedSortFilter.value,
          filter: selectedFilter.value,
          builder_id: builderId.value,
        });
      }

      return fetchItems(false); // Retry once, prevent infinite loops
    }

    // Ensure unique models based on `model.name`
    const existingModelNames = new Set(models.value.map((model) => model.name));
    const uniqueModels = result.models.filter((model) => !existingModelNames.has(model.name));

    // Update models list
    models.value = page.value === 1 ? uniqueModels : [...models.value, ...uniqueModels];

    hasMore.value = result.more;
    page.value++;
  } catch ({}) {
    showGlobalToast("error", "", "Error fetching models");
  }
};

// const selectFilter = (filter) => {
//   selectedFilter.value = filter.code;

//   if (
//     filter.code === "free" &&
//     ["most_expensive", "least_expensive"].includes(selectedSortFilter.value)
//   ) {
//     selectedSortFilter.value = "recently_updated";
//   }

//   router
//     .push({ path: route.path, query: { ...route.query, filter: selectedFilter.value } })
//     .then(resetSearch);
// };

const filterChanged = () => {
  builderId.value = "";
  const newQuery = { ...route.query };
  if (newQuery.builder_id) {
    delete newQuery.builder_id;
  }

  newQuery.filter = selectedFilter.value;

  router.push({ path: route.path, query: newQuery }).then(resetSearch);
};

const resetSearch = () => {
  page.value = 1;
  models.value = [];
  hasMore.value = false;
  fetchItems(false); // Avoid retry loop
};
const loadMore = () => fetchItems(false);
const debouncedSearch = useDebounceFn(resetSearch, 300);
const debounceSearch = () => debouncedSearch();

updateMeta({
  title: "4CPredictions - All AI Models",
});

watch(
  () => authStore.user,
  () => {
    if (authStore.user && !filters.value.find((x) => x.code == "subscribed")) {
      filters.value.splice(1, 0, { name: "My Ai Models", code: "subscribed" });
    }
  },
  { immediate: true },
);

watch(
  () => route.params.type,
  () => {
    if (route.params.type) {
      type.value = route.params.type as string;
      selectedFilter.value = "all";
    } else {
      type.value = "";
      subType.value = "";
    }

    // Update the route correctly
    updateRoute();
  },
);

watch(
  () => route.params.subType,
  () => {
    if (route.params.subType) {
      subType.value = route.params.subType as string;
      selectedFilter.value = "all";
    } else {
      subType.value = "";
    }

    // Update the route correctly
    updateRoute();
  },
);

watch(
  () => route.query.builder_id,
  () => {
    if (route.query.builder_id) {
      builderId.value = route.query.builder_id as string;
    } else {
      builderId.value = "";
    }
  },
  { immediate: true },
);

// Function to update the route properly
const updateRoute = () => {
  const newQuery = { ...route.query };

  if (type.value) {
    newQuery.type = type.value;
  } else {
    delete newQuery.type;
  }

  if (subType.value) {
    newQuery.subType = subType.value;
  } else {
    delete newQuery.subType;
  }

  router.push({ path: route.path, query: newQuery }).then(resetSearch);
};

onMounted(fetchItems);
</script>
