<template>
  <div class="min-w-screen flex min-h-screen flex-col items-center justify-center">
    <component v-if="loaded" :is="activePage" />
    <i v-else class="pi pi-spin pi-spinner text-[2rem]"></i>
  </div>
</template>

<script setup lang="ts">
import type { Component } from "vue";
import { computed, onMounted, ref, shallowRef, watch } from "vue";
import { useRoute } from "vue-router";
import BaseballView from "./pages/BaseballView.vue";
import BasketballView from "./pages/BasketballView.vue";
import DefaultView from "./pages/DefaultView.vue";
import NflView from "./pages/NflView.vue";
import SoccerView from "./pages/SoccerView.vue";

const route = useRoute();

const loaded = ref(false);
const allowedPages = {
  soccer: SoccerView,
  baseball: BaseballView,
  basketball: BasketballView,
  nfl: NflView,
  default: DefaultView,
};
const activePage = shallowRef<Component>(null);

const isValidPage = computed(() => Object.keys(allowedPages).includes(route.params.slug as string));

const updateSlug = async () => {
  const slug = isValidPage.value ? (route.params.slug as string).toLowerCase() : "default";
  activePage.value = allowedPages[slug];
  loaded.value = true;
};

onMounted(updateSlug);

watch(() => route.params.topic, updateSlug);
</script>
