<script setup lang="ts">
import BannerImage from "@/assets/images/builders/builder-cubes.webp";
import { onMounted, ref } from "vue";

const isMobile = ref(false);

onMounted(() => {
  isMobile.value = window.innerWidth < 768;
});
</script>

<template>
  <section id="home" class="mx-auto w-full pt-[90px] md:max-w-[1312px]">
    <div class="flex flex-col items-center justify-between self-stretch md:flex-row md:gap-36">
      <div class="flex w-full flex-1 flex-col gap-6 p-6 md:w-auto md:p-0">
        <slot></slot>
      </div>
      <div class="mb-5">
        <img :src="BannerImage" class="w-[90vw] md:w-[471px]" />
      </div>
    </div>
  </section>
</template>
