<script setup lang="ts">
import { authApi } from "@/api/authApi";
import { paymentApi } from "@/api/paymentApi";
import { useCookieTracking } from "@/composables/useCookieTracking";
import { useDataLayer } from "@/composables/useDataLayer";
import { useAuthStore } from "@/stores/authStore";
import type { User } from "@/types/user";
import AuthLayout from "@/ui/layouts/AuthLayout.vue";
import StringHelper from "@/utils/stringHelper";

import BaseballImage from "@/assets/images/register/baseball_bg_1.webp";
import BasketballImage from "@/assets/images/register/basketball_bg_1.webp";
import DefaultImage from "@/assets/images/register/default_bg_1.webp";
import FootballImage from "@/assets/images/register/football_bg_1.webp";
import SoccerImage from "@/assets/images/register/soccer_bg_1.webp";
import useVuelidate from "@vuelidate/core";
import { email, helpers, minLength, required, sameAs } from "@vuelidate/validators";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();

const { getTrackingCookies } = useCookieTracking();
const { pushToDataLayer } = useDataLayer();

const bgImage = ref("");
const emailAddress = ref("");
const password = ref("");
const backendError = ref("");
const acceptTerms = ref(false);
const target = ref("");

const rules = computed(() => ({
  emailAddress: { required, email },
  password: { required, minLength: minLength(8) },
  acceptTerms: { required: helpers.withMessage("You must accept the terms.", sameAs(true)) },
}));

const v$ = useVuelidate(rules, {
  emailAddress,
  password,
  acceptTerms,
});

const requestPaymentLink = async (user: User) => {
  const link = `${window.location.origin}/events/march-madness`;
  const result = await paymentApi.requestLink(user, link);
  window.location.href = result.payment_url;
};

const submit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect) {
    authStore.setIsLoading(true);
    try {
      const trackingCookies = getTrackingCookies();
      trackingCookies.client_ip = authStore.userIp;

      const userData = await authApi.register({
        first_name: "4C",
        last_name: "Friend",
        email: emailAddress.value,
        password: password.value,
        birth_year: "2000",
        accepted_marketing: true,
        accepted_risk: true,
        accepted_terms: acceptTerms.value,
        cookies: trackingCookies,
        free_trial: false,
      });

      if (userData) {
        pushToDataLayer({
          event: "Register",
          eventCategory: "Register",
          eventAction: "submit",
          eventLabel: "RegisterForm",
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "sign_up_fe",
        });

        authStore.setAuthModalVisible(false);
        authStore.setUser(userData);

        if (target.value === "get-predictions") {
          requestPaymentLink(userData);
        } else {
          router.push({
            name: "register-upsell",
            query: { target: target.value },
          });
        }
        authStore.setIsLoading(false);
      }
    } catch (error) {
      const err = error as { response?: { data?: { errors?: unknown } } };
      const errors = err.response?.data?.errors;
      if (errors) {
        if (typeof errors === "string") {
          backendError.value = errors;
        } else if (typeof errors === "object") {
          const firstKey = Object.keys(errors)[0];
          const firstError = Array.isArray(errors[firstKey])
            ? errors[firstKey][0]
            : errors[firstKey];
          backendError.value = firstError;
        } else {
          backendError.value = "Registration failed.";
        }
      } else {
        backendError.value = "Registration failed.";
      }
      authStore.setIsLoading(false);
    }
  }
};

watch(
  () => route.query.target,
  () => {
    const _target = route.query.target as string;
    target.value = _target?.toLowerCase();

    if (target.value) {
      switch (target.value) {
        case "soccer":
          bgImage.value = SoccerImage;
          break;
        case "baseball":
          bgImage.value = BaseballImage;
          break;
        case "basketball":
          bgImage.value = BasketballImage;
          break;
        case "football":
          bgImage.value = FootballImage;
          break;
        case "default":
          target.value = "";
          bgImage.value = DefaultImage;
          break;
      }
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (authStore.user) {
    router.push({ name: "home" });
  }
});
</script>

<template>
  <AuthLayout :variant="target" :bgImage="bgImage">
    <div>
      <div class="justify-start self-stretch text-center text-[32px] font-medium text-slate-950">
        <strong>Stop</strong> Guessing. <strong>Start</strong> Winning.
      </div>
      <div class="justify-start self-stretch text-center">
        Join for free to unlock winning AI {{ target.toLowerCase() }} predictions - trusted by
        thousands.
      </div>
    </div>
    <form
      @submit.prevent="submit"
      class="relative flex w-full flex-col items-start justify-start rounded-xl bg-white p-6 shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)] md:max-w-[400px]"
    >
      <div class="mb-2 flex items-start justify-start gap-2.5 self-stretch">
        <div class="flex flex-1 flex-col items-start justify-start">
          <div class="mb-[7px] self-stretch text-sm font-medium leading-none text-slate-700">
            Email Address
          </div>
          <InputText
            v-model="emailAddress"
            @blur="v$.emailAddress.$touch()"
            :class="{ 'p-invalid': v$.emailAddress.$invalid && v$.emailAddress.$dirty }"
            placeholder="Enter your email address"
            type="email"
            class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0pxtexba(18,18,23,0.05)]"
          />
          <div
            v-if="v$.emailAddress.$dirty && v$.emailAddress.$invalid"
            class="p-error text-xs text-red-600"
          >
            <div v-if="(v$.emailAddress.required as any)?.$invalid">Email is required.</div>
            <div v-else-if="(v$.emailAddress.email as any)?.$invalid">
              Please enter a valid email address.
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2 flex items-start justify-start gap-2.5 self-stretch">
        <div class="flex flex-1 flex-col items-start justify-start">
          <div class="my-[7px] self-stretch text-sm font-medium leading-none text-slate-700">
            Choose Password
          </div>
          <Password
            v-model="password"
            @blur="v$.password.$touch()"
            :class="{ 'p-invalid': v$.password.$invalid && v$.password.$dirty }"
            toggleMask
            :feedback="false"
            placeholder="Enter a password"
            class="h-[35px] w-full border-slate-300 bg-white text-sm font-normal text-slate-500"
          />
          <div
            v-if="v$.password.$dirty && v$.password.$invalid"
            class="p-error text-xs text-red-600"
          >
            <div v-if="(v$.password.required as any)?.$invalid">Password is required.</div>
            <div v-else-if="(v$.password.minLength as any)?.$invalid">
              Password must be at least 8 characters long.
            </div>
          </div>
        </div>
      </div>
      <div class="my-2 inline-flex items-start justify-start gap-[7px] self-stretch rounded-md">
        <Checkbox
          v-model="acceptTerms"
          @blur="v$.acceptTerms.$touch()"
          :class="{ 'p-invalid': v$.acceptTerms.$invalid && v$.acceptTerms.$dirty }"
          class="relative top-[3px]"
          inputId="acceptTerms"
          name="acceptTerms"
          :binary="true"
        />
        <label for="acceptTerms" class="flex-1 justify-start text-[13px] font-normal">
          I have read and accept 4C Predictions
          <router-link to="/terms-of-use" class="underline">Terms and Conditions</router-link>
          and
          <router-link to="/privacy-policy" class="underline">Privacy Policy.</router-link>
        </label>
      </div>
      <div
        v-if="v$.acceptTerms.$dirty && v$.acceptTerms.$invalid"
        class="p-error mb-1 text-xs text-red-600"
      >
        <div v-if="(v$.acceptTerms.required as any)?.$invalid">
          You must accept terms & conditions
        </div>
      </div>
      <div v-if="backendError" class="p-error mt-2 text-xs text-red-600">
        {{ backendError }}
      </div>
      <Button
        type="submit"
        :disabled="authStore.isLoading"
        class="mt-3 w-full px-[10.50px] py-[7px] shadow"
      >
        <i v-if="authStore.isLoading" class="pi pi-spin pi-spinner"></i>
        <div class="text-[1.2rem] text-base font-bold uppercase">
          Get Your {{ StringHelper.ucFirst(target) }}
          {{ target.length == 0 ? "AI" : "" }} Predictions now!
        </div>
      </Button>
    </form>
  </AuthLayout>
</template>
