<script setup lang="ts">
import PlainLayout from "@/ui/layouts/PlainLayout.vue";
import Button from "primevue/button";
import { ref } from "vue";
import { useRoute } from "vue-router";
import CancelSubscriptionDialog from "@/ui/components/profile/CancelSubscriptionDialog.vue";

const route = useRoute();
const loading = ref(false);
const cancelSubscriptionDialogVisible = ref(false);

const goBack = () => {
  history.back();
};

</script>

<template>
  <PlainLayout>
    <div className="max-w-4xl mx-auto px-6 py-4 md:py-12">
      <div class="mx-auto max-w-3xl rounded-lg bg-white p-6 shadow-lg md:px-10">
        <a @click="goBack" title="Go Back" style="cursor: pointer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
            class="h-6 w-6 text-gray-500 hover:text-gray-700">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H3m0 0l6-6m-6 6l6 6" />
          </svg>
        </a>
        <h1 class="mb-6 text-center text-3xl font-bold">
          Manage Subscription
        </h1>
        <div class="flex justify-center">
          <Button class="h-8 border-black bg-transparent px-5" @click="cancelSubscriptionDialogVisible = true"
            :disabled="loading">
            <span class="leading-0 text-xs font-semibold text-black">Cancel Subscription</span>
          </Button>
        </div>
      </div>
    </div>
  </PlainLayout>

  <CancelSubscriptionDialog v-model:showDialog="cancelSubscriptionDialogVisible"
    :subscription-type="route.query.subscriptionType === 'new' ? 'new' : 'old'" />

</template>
