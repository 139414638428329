<script setup lang="ts">
import FullLogo from "@/assets/images/full-logo.webp";
import { onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";
import LandingNavigation from "./LandingNavigation.vue";

const router = useRouter();
const mobileOpen = ref(false);
const btnText = ref("");
const scrolled = ref(false);

const props = defineProps<{
  variant: string;
}>();

const closeMobile = () => {
  if (mobileOpen.value) {
    mobileOpen.value = false;
  }
};

const toggleMobile = () => {
  mobileOpen.value = !mobileOpen.value;
};

const handleScroll = () => {
  scrolled.value = window.scrollY > 10;
};

onMounted(() => {
  handleScroll();
  window.addEventListener("scroll", handleScroll);

  switch (props.variant.toLowerCase()) {
    case "soccer":
      btnText.value = "Get Your Soccer Picks Now!";
      break;
    case "baseball":
      btnText.value = "Get Your Baseball Picks Now!";
      break;
    case "basketball":
      btnText.value = "Get Your Basketball Picks Now!";
      break;
    case "football":
      btnText.value = "Get Your Football Picks Now!";
      break;
    default:
      btnText.value = "Get Your AI Predictions Now!";
  }
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <header
    :class="[
      'fixed z-50 h-[80px] w-full transition-colors duration-300',
      scrolled || mobileOpen ? 'bg-[#020617]' : 'bg-transparent',
    ]"
  >
    <div
      class="mx-auto flex h-full w-full max-w-[1312px] items-center justify-between gap-5 px-4 text-white"
    >
      <router-link :to="{ name: 'home' }"><img :src="FullLogo" class="h-[44px]" /></router-link>
      <nav class="hidden flex-1 items-center justify-center gap-8 md:flex">
        <LandingNavigation />
      </nav>
      <Button
        @click="router.push({ name: 'register', query: { target: variant.toLowerCase() } })"
        class="hidden !px-6 md:block"
      >
        <span class="text-nowrap text-[1.2rem] font-bold uppercase text-black">{{ btnText }}</span>
      </Button>
      <Button
        @click="toggleMobile"
        class="block border-none bg-transparent p-0 hover:bg-transparent md:hidden"
      >
        <i :class="`pi pi-${mobileOpen ? 'times' : 'bars'} text-xl text-white`"></i>
      </Button>
    </div>
  </header>

  <nav
    v-if="mobileOpen"
    class="fixed left-0 top-[80px] z-20 flex h-[calc(100%-80px)] w-full flex-col items-start gap-5 bg-[#020617] px-6 py-4 md:hidden"
  >
    <LandingNavigation @clicked="closeMobile" />
    <Button
      @click="router.push({ name: 'register', query: { target: variant.toLowerCase() } })"
      class="w-full"
    >
      <span class="text-nowrap text-[1.2rem] font-bold uppercase text-black">{{ btnText }}</span>
    </Button>
  </nav>
</template>
