<template>
  <Carousel
    :value="cards"
    :numVisible="4"
    :numScroll="1"
    :responsiveOptions="responsiveOptions"
    :showNavigators="false"
    :showDots="false"
    :showIndicators="false"
    :itemClass="'carousel-item'"
  >
    <template #item="slotProps">
      <div
        class="dashboard-banner relative m-2 flex h-56 flex-col items-center justify-between overflow-hidden rounded-xl md:m-4 md:mb-2 md:h-64"
      >
        <div
          :style="{
            backgroundImage: `url(${slotProps.data.image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
          class="dashboard-banner-image relative z-0 inline-flex h-[160px] w-full items-center justify-center overflow-hidden rounded-xl bg-black md:h-[200px]"
        ></div>

        <div
          :class="`absolute bottom-0 mb-2 flex h-[106px] w-[90%] flex-col items-start justify-center gap-[17.50px] overflow-hidden rounded-xl bg-gradient-to-r md:h-[130.50px] ${slotProps.data.gradient} p-[17.50px] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.30)]`"
        >
          <div class="flex h-[30px] flex-col items-start justify-center gap-[7px] md:h-[45px]">
            <div class="text-md self-stretch font-semibold text-black md:text-lg">
              {{ slotProps.data.title }}
            </div>
            <div class="inline-flex items-start justify-start gap-[7px] self-stretch">
              <div class="shrink grow basis-0 text-xs font-normal text-black md:text-sm">
                {{ slotProps.data.description }}
              </div>
            </div>
          </div>
          <Button
            v-if="slotProps.data.actionText === 'Unlock'"
            @click="openAuthModal"
            :class="`inline-flex min-h-8 min-w-[80px] items-center justify-center gap-[7px] rounded-md border ${slotProps.data.actionStyle} px-[10.50px] py-[5px]`"
          >
            <div class="font-['Inter'] text-sm font-medium text-white">
              {{ slotProps.data.actionText }}
            </div>
          </Button>
          <router-link
            v-else
            :to="slotProps.data.link"
            :class="`inline-flex min-w-[80px] items-center justify-center gap-[7px] rounded-md border ${slotProps.data.actionStyle} px-[10.50px] py-[5px]`"
          >
            <div class="font-['Inter'] text-sm font-medium text-white">
              {{ slotProps.data.actionText }}
            </div>
          </router-link>
        </div>
      </div>
    </template>
  </Carousel>

  <Dialog
    :visible="subscribeVisible"
    :style="{ maxWidth: '420px', width: '100%' }"
    :modal="true"
    :closable="false"
    :closeOnEscape="false"
  >
    <template #header>
      <div class="inline-flex w-full items-start justify-between">
        <div class="justify-start self-stretch text-2xl font-bold leading-normal text-slate-700">
          <!-- March Madness Exclusive! -->
          NBA Playoffs Exclusive!
        </div>
        <Button
          @click="subscribeVisible = false"
          class="flex h-[33px] w-[33px] items-center justify-center rounded-md border border-slate-200 bg-transparent p-0"
        >
          <div class="relative h-3.5 w-3.5 overflow-hidden">
            <SvgIcon name="close" />
          </div>
        </Button>
      </div>
    </template>
    <div class="inline-flex w-full flex-col items-start justify-start gap-3.5">
      <div class="flex flex-col items-start justify-start self-stretch">
        <div class="flex flex-col items-center justify-center gap-2 self-stretch">
          <div class="inline-flex items-start justify-start gap-2 self-stretch">
            <div class="justify-center text-sm font-normal text-slate-700 md:text-base">✅</div>
            <div class="flex-1 justify-center">
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >Unlimited Sports Access.</span
              >
            </div>
          </div>
          <div class="inline-flex items-start justify-start gap-2 self-stretch">
            <div class="justify-center text-sm font-normal text-slate-700 md:text-base">✅</div>
            <div class="flex-1 justify-center">
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >Every
              </span>
              <span class="text-sm font-normal leading-tight text-slate-500 md:text-base"
                >Sport,
              </span>
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >Every
              </span>
              <span class="text-sm font-normal leading-tight text-slate-500 md:text-base"
                >Prediction.
              </span>
            </div>
          </div>
          <div class="inline-flex items-start justify-start gap-2 self-stretch">
            <div class="justify-center text-sm font-normal text-slate-700 md:text-base">✅</div>
            <div class="flex-1 justify-center">
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >Instant Picks,
              </span>
              <span class="text-sm font-normal leading-tight text-slate-500 md:text-base"
                >No Waiting.</span
              >
            </div>
          </div>
          <div class="inline-flex items-start justify-start gap-2 self-stretch">
            <div class="justify-center text-sm font-normal text-slate-700 md:text-base">✅</div>
            <div class="flex-1 justify-center">
              <span class="text-sm font-normal leading-tight text-slate-500 md:text-base"
                >Get the edge
              </span>
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >sports books fear.</span
              >
            </div>
          </div>
          <div class="inline-flex items-start justify-start gap-2 self-stretch">
            <div class="justify-center text-sm font-normal text-slate-700 md:text-base">✅</div>
            <div class="flex-1 justify-center">
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >Sharp bettors
              </span>
              <span class="text-sm font-normal leading-tight text-slate-500 md:text-base"
                >use AI.</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="justify-start self-stretch">
        <span class="text-xs font-normal leading-normal text-slate-500 md:text-sm">🏆 </span
        ><span class="text-xs font-semibold leading-normal text-slate-700 md:text-sm"
          >Trusted by 50,000+ Fans</span
        ><span class="text-xs font-normal leading-normal text-slate-500 md:text-sm">
          Winning with AI Predictions!</span
        >
      </div>
      <div
        class="flex flex-col items-center justify-center gap-2 self-stretch rounded-lg bg-sky-50 p-4 outline outline-1 outline-offset-[-1px] outline-sky-500"
      >
        <div class="inline-flex items-center justify-between self-stretch">
          <div class="justify-start text-center">
            <span class="text-sm font-normal leading-normal text-slate-700">🚀 </span>
            <!-- <span class="text-sm font-semibold leading-normal text-slate-700">March Madness Special</span> -->
            <span class="text-sm font-semibold leading-normal text-slate-700"
              >NBA Playoffs Special</span
            >
          </div>
          <div class="flex items-center justify-start gap-1">
            <div
              class="justify-center text-center text-sm font-semibold leading-none text-slate-700 md:text-base"
            >
              $19
            </div>
            <div class="relative top-[-0.5px] justify-start text-center">
              <span class="text-sm font-normal leading-none text-red-600 line-through">$199</span>
              <span class="text-sm font-bold leading-none text-red-600"> </span>
            </div>
          </div>
        </div>
        <div class="flex w-full flex-col items-start justify-start gap-1">
          <Button
            :disabled="isLoading"
            @click="requestPaymentLink"
            class="flex w-full flex-1 items-center justify-center gap-1 rounded-md border-sky-500 bg-sky-500 px-[7px] py-3"
          >
            <i v-if="isLoading" class="pi pi-spin pi-spinner text-white"></i>
            <UnLockIcon v-if="!isLoading" class="h-4 w-4" />
            <div class="justify-start text-sm font-semibold text-white">Unlock Predictions</div>
          </Button>
          <div
            class="w-full justify-start text-center text-[11px] font-normal leading-normal text-slate-500"
          >
            (Limited-time offer. Cancel Anytime. Don't miss out!)
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { paymentApi } from "@/api/paymentApi";
import BaseballImage from "@/assets/images/banner/videos/baseball.webp";
import BasketballImage from "@/assets/images/banner/videos/basketball.webp";
import BrainImage from "@/assets/images/banner/videos/brain.webp";
import SoccerImage from "@/assets/images/banner/videos/soccer.webp";
import UnlockImage from "@/assets/images/banner/videos/unlock.webp";
import { useAuthStore } from "@/stores/authStore";
import SvgIcon from "./SvgIcon.vue";

import { ref, watchEffect } from "vue";
import UnLockIcon from "./icons/UnLockIcon.vue";

const subscribeVisible = ref(false);
const isLoading = ref(false);
const authStore = useAuthStore();
const genericImage = ref(null);
const genericButtonText = ref(null);
const genericTitle = ref(null);
const genericDescription = ref(null);
const clicked = ref(false);

const requestPaymentLink = async () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "initiateCheckout",
    ecommerce: {
      // value: props.model.price,
      value: 19,
      currency: "USD",
    },
  });

  isLoading.value = true;
  const result = await paymentApi.requestLink(authStore.user, window.location.href);
  window.location.href = result.payment_url;
};

async function openAuthModal() {
  if (authStore.user) {
    subscribeVisible.value = true;
    return;
  }
  authStore.setAuthAndGoPay(false);
  authStore.setActiveModal("join");
  authStore.setAuthModalVisible(true);
  clicked.value = true;
}

watchEffect(() => {
  if (!authStore.authModalVisible && authStore.user && clicked.value) {
    subscribeVisible.value = true;
    clicked.value = false;
  }
});

const cards = ref([
  {
    image: BasketballImage,
    title: "NBA",
    description: "Who will win the Championship?",
    actionText: "View",
    actionStyle: "border-blue-800 bg-blue-800",
    link: "/events/basketball",
    gradient: "from-sky-300 to-pink-300",
  },
  {
    image: SoccerImage,
    title: "English Premier League",
    description: "Who will win the title?",
    actionText: "View",
    actionStyle: "border-violet-500 bg-violet-500",
    link: "/events/soccer",
    gradient: "from-violet-300 to-pink-300",
  },
  {
    image: BaseballImage,
    title: "Major League Baseball",
    description: "Favorites to win the World Series?",
    actionText: "View",
    actionStyle: "border-red-600 bg-red-600",
    link: "/events/baseball",
    gradient: "from-red-200 to-sky-200",
  },
  {
    image: genericImage,
    title: genericTitle,
    description: genericDescription,
    actionText: genericButtonText,
    actionStyle: "border-blue-500 bg-blue-500",
    link: "/models",
    gradient: "from-sky-300 to-amber-300",
  },
]);

const responsiveOptions = ref([
  {
    breakpoint: "1400px",
    numVisible: 4,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 4,
    numScroll: 1,
  },
  {
    breakpoint: "767px",
    numVisible: 2.1,
    numScroll: 2,
  },
  {
    breakpoint: "575px",
    numVisible: 1.2,
    numScroll: 1,
  },
]);

watchEffect(() => {
  if (authStore.user && authStore.user?.has_access) {
    genericImage.value = BrainImage;
    genericButtonText.value = "View";
    genericTitle.value = "Explore AI Models";
    genericDescription.value = "Discover the top AI predictions.";
  } else {
    genericImage.value = UnlockImage;
    genericButtonText.value = "Unlock";
    genericTitle.value = "Unlock Premium Predictions";
    genericDescription.value = "Use AI to predict the future.";
  }
});
</script>
