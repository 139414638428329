import axiosInstance from '@/api/axiosInstance';
import type { Builder, User } from '@/types/user';
import { useLocalStorage } from '@vueuse/core';
import { type AxiosInstance } from 'axios';

const api: AxiosInstance = axiosInstance;
const access_token = useLocalStorage('4c_ath_tkn', null);


export const authApi = {
    async login(data): Promise<User> {
        const response = await api.post('/login', data);
        access_token.value = JSON.stringify(response.data);
        return response.data.user;
    },
    async forgot(data) {
        const response = await api.post('/forgot-password', data);
        return response;
    },
    async resendPasswordLink(data) {
        const response = await api.post('/resend-password-link', data);
        return response;
    },
    async reset(data) {
        const response = await api.post('/reset-password', data);
        return response;
    },

    async register(data): Promise<User> {
        const response = await api.post('/register', data);
        access_token.value = JSON.stringify(response.data);
        return response.data.user;
    },

    async registerBuilder(data: FormData): Promise<Builder | null> {
        const response = await api.post('/register-builder', data);
        return response.data.data ? response.data.data : null;
    },

    async emailVerify(id: string, hash: string, query: Record<string, string>) {
        const queryString = new URLSearchParams(query).toString();
        const response = await api.get(`/email/verify/${id}/${hash}?${queryString}`);
        return response.data;
    },

    async logout() {
        const response = await api.post('/logout');
        access_token.value = null;
        return response.data.data;
    },

    async fetchUser(): Promise<User> {
        const response = await api.get('/user');
        return response.data.data;
    },

    async changeLanguage(lang: string) {
        const response = await api.post('/auth/user/change-language', { language: lang });
        return response.data.data;
    },
};
