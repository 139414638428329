<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const isMobile = ref(false);
const activeSection = ref("#home");
const emit = defineEmits(["clicked"]);

const navItems = [
  {
    title: "Home",
    link: "#home",
  },
  {
    title: "Why Apply?",
    link: "#why-apply",
  },
  {
    title: "Why It's Worth It?",
    link: "#why-worth",
  },
];

const observer = ref<IntersectionObserver | null>(null);

const scrollToView = (to?: string) => {
  const nav = (to ?? window.location.hash) || "#home";
  const element = document.querySelector(nav);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    activeSection.value = nav;
    if (nav !== "#home") {
      history.replaceState(history.state, "", nav);
    }
  }
};

const handleClick = (to: string) => {
  if (route.name == "creator.welcome") {
    scrollToView(to);
    emit("clicked");
  } else {
    router.push({ name: "creator.welcome", hash: to });
  }
};

const observeSections = () => {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.6,
  };

  observer.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        activeSection.value = `#${entry.target.id}`;
      }
    });
  }, options);

  navItems.forEach((item) => {
    const section = document.querySelector(item.link);
    if (section) observer.value?.observe(section);
  });
};

onMounted(() => {
  scrollToView();
  isMobile.value = window.innerWidth < 768;
  observeSections();
});

onBeforeUnmount(() => {
  observer.value?.disconnect();
});
</script>

<template>
  <nav :class="{ 'flex-col gap-4': isMobile, 'gap-5': !isMobile }" class="flex">
    <a
      v-for="(item, index) in navItems"
      :key="index"
      :class="[
        'cursor-pointer text-nowrap transition-colors duration-300',
        item.link === activeSection && route.name == 'creator.welcome'
          ? 'text-primary'
          : 'text-white',
      ]"
      @click.prevent="handleClick(item.link)"
    >
      {{ item.title }}
    </a>
    <router-link
      :to="{ name: 'contact-us' }"
      class="cursor-pointer text-nowrap text-white transition-colors duration-300"
    >
      Contact
    </router-link>
  </nav>
</template>
