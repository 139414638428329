<script setup lang="ts">
import { onMounted } from "vue";

onMounted(() => {
  const existingScript = document.querySelector(
    'script[src="https://static.elfsight.com/platform/platform.js"]',
  );
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    document.body.appendChild(script);
  }
});
</script>

<template>
  <div class="elfsight-app-45369efd-ce47-4a46-bebd-5ae5f8b8f8c1 mt-8" data-elfsight-app-lazy></div>
</template>
