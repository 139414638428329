/* eslint-disable vue/multi-word-component-names */
/* eslint-disable vue/no-reserved-component-names */
import { createPinia } from "pinia";
import "primeicons/primeicons.css";
import { createApp } from "vue";
import "./assets/main.css";

import App from "./App.vue";
import router from "./router";

import PrimeVue from "primevue/config";

import MetaProvider from "@/ui/layouts/MetaProvider.vue";
import { CustomTheme } from "@/utils/4cPlatformTheme";
import AutoComplete from "primevue/autocomplete";
import Avatar from "primevue/avatar";
import Button from "primevue/button";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import DatePicker from "primevue/datepicker";
import Dialog from "primevue/dialog";
import IconField from "primevue/iconfield";
import Image from "primevue/image";
import InputIcon from "primevue/inputicon";
import InputOtp from "primevue/inputotp";
import InputText from "primevue/inputtext";
import Panel from "primevue/panel";
import Password from "primevue/password";
import ProgressBar from "primevue/progressbar";
import RadioButton from "primevue/radiobutton";
import Select from "primevue/select";
import SelectButton from "primevue/selectbutton";
import Skeleton from "primevue/skeleton";
import Tag from "primevue/tag";
import ToastService from "primevue/toastservice";

const app = createApp(App);

const checkRedirect = () => {
  const environment = import.meta.env.VITE_NODE_ENV;
  if (environment == "production" && typeof window !== undefined && window.location.hostname !== "platform.4cpredictions.ai") {
    const newUrl =
      "https://platform.4cpredictions.ai" +
      window.location.pathname +
      window.location.search +
      window.location.hash;

    window.location.href = newUrl;

    return;
  }
};

checkRedirect();

app.use(router);
app.use(PrimeVue, CustomTheme);
app.use(createPinia());

app.component("MetaProvider", MetaProvider);
app.component("Button", Button);
app.component("InputText", InputText);
app.component("Password", Password);
app.component("IconField", IconField);
app.component("InputIcon", InputIcon);
app.component("InputOtp", InputOtp);
app.component("RadioButton", RadioButton);
app.component("ProgressBar", ProgressBar);
app.component("Skeleton", Skeleton);
app.component("Carousel", Carousel);
app.component("Select", Select);
app.component("SelectButton", SelectButton);
app.component("DatePicker", DatePicker);
app.component("Dialog", Dialog);
app.component("Checkbox", Checkbox);
app.component("Chip", Chip);
app.component("Card", Card);
app.component("Panel", Panel);
app.component("Tag", Tag);
app.component("AutoComplete", AutoComplete);
app.component("Avatar", Avatar);
app.component("Image", Image);

app.use(ToastService);
app.mount("#app");
