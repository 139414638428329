<script setup lang="ts">
import { authApi } from "@/api/authApi";
import { paymentApi } from "@/api/paymentApi";
import { useCookieTracking } from "@/composables/useCookieTracking";
import { useDataLayer } from "@/composables/useDataLayer";
import { useAuthStore } from "@/stores/authStore";
import type { User } from "@/types/user";
import MarchMadnessLayout from "@/ui/layouts/MarchMadnessLayout.vue";
import useVuelidate from "@vuelidate/core";
import { email, helpers, minLength, required, sameAs } from "@vuelidate/validators";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();

const { getTrackingCookies } = useCookieTracking();
const { pushToDataLayer } = useDataLayer();

const emailAddress = ref("");
const password = ref("");
const acceptTerms = ref(false);
const target = ref("landing");

const rules = computed(() => ({
  emailAddress: { required, email },
  password: { required, minLength: minLength(8) },
  acceptTerms: { required: helpers.withMessage("You must accept the terms.", sameAs(true)) },
}));

const v$ = useVuelidate(rules, {
  emailAddress,
  password,
  acceptTerms,
});

const requestPaymentLink = async (user: User) => {
  const link = `${window.location.origin}/events/march-madness`;
  const result = await paymentApi.requestLink(user, link);
  window.location.href = result.payment_url;
};

const submit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect) {
    authStore.setIsLoading(true);
    try {
      const trackingCookies = getTrackingCookies();
      trackingCookies.client_ip = authStore.userIp;

      const userData = await authApi.register({
        first_name: "4C",
        last_name: "Friend",
        email: emailAddress.value,
        password: password.value,
        birth_year: "2000",
        accepted_marketing: true,
        accepted_risk: true,
        accepted_terms: acceptTerms.value,
        cookies: trackingCookies,
        free_trial: false,
      });

      if (userData) {
        pushToDataLayer({
          event: "Register",
          eventCategory: "Register",
          eventAction: "submit",
          eventLabel: "RegisterForm",
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "sign_up_fe",
        });

        authStore.setAuthModalVisible(false);
        authStore.setUser(userData);

        if (target.value === "get-predictions") {
          requestPaymentLink(userData);
        } else {
          router.push({
            name: "march-madness-upsell",
          });
        }
        authStore.setIsLoading(false);
      }
    } finally {
      //authStore.setIsLoading(false);
    }
  }
};

watch(
  () => route.query.target,
  () => {
    const _target = route.query.target as string;
    target.value = _target?.toLowerCase();
  },
  { immediate: true },
);

onMounted(() => {
  if (authStore.user) {
    router.push({ name: "events", params: { type: "march-madness" } });
  }
});
</script>

<template>
  <MarchMadnessLayout>
    <div v-if="target == 'get-predictions'">
      <div class="justify-start self-stretch text-center">
        <span class="text-[32px] font-medium leading-10 text-slate-950">Get&nbsp;</span
        ><span class="text-[32px] font-bold leading-10 text-slate-950"
          >Exclusive March Madness Predictions!</span
        >
      </div>
      <div class="justify-start self-stretch text-center">
        <span class="text-sm font-normal text-black">You're&nbsp;</span
        ><span class="text-sm font-semibold text-black"
          >one step away from expert AI-driven March Madness predictions - create your
          account&nbsp;</span
        ><span class="text-sm font-normal text-black">now to win big!</span>
      </div>
    </div>
    <div v-else>
      <div class="justify-start self-stretch text-center">
        <span class="text-[32px] font-medium leading-10 text-slate-950">Get your&nbsp;</span
        ><span class="text-[32px] font-bold leading-10 text-slate-950"
          >FREE March Madness Brackets!</span
        >
      </div>
      <div class="justify-start self-stretch text-center">
        <span class="text-sm font-normal text-black"
          >Create your <b>FREE</b> Account now so we can </span
        ><span class="text-sm font-semibold text-black">email your free brackets</span>
      </div>
    </div>
    <form
      @submit.prevent="submit"
      class="relative flex w-full flex-col items-start justify-start rounded-xl bg-white p-6 shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)] md:max-w-[400px]"
    >
      <div class="mb-2 flex items-start justify-start gap-2.5 self-stretch">
        <div class="flex flex-1 flex-col items-start justify-start">
          <div class="mb-[7px] self-stretch text-sm font-medium leading-none text-slate-700">
            Email Address
          </div>
          <InputText
            v-model="emailAddress"
            @blur="v$.emailAddress.$touch()"
            :class="{ 'p-invalid': v$.emailAddress.$invalid && v$.emailAddress.$dirty }"
            type="email"
            class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0pxtexba(18,18,23,0.05)]"
          />
          <div
            v-if="v$.emailAddress.$dirty && v$.emailAddress.$invalid"
            class="p-error text-xs text-red-600"
          >
            <div v-if="(v$.emailAddress.required as any)?.$invalid">Email is required.</div>
            <div v-else-if="(v$.emailAddress.email as any)?.$invalid">
              Please enter a valid email address.
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2 flex items-start justify-start gap-2.5 self-stretch">
        <div class="flex flex-1 flex-col items-start justify-start">
          <div class="my-[7px] self-stretch text-sm font-medium leading-none text-slate-700">
            Choose Password
          </div>
          <Password
            v-model="password"
            @blur="v$.password.$touch()"
            :class="{ 'p-invalid': v$.password.$invalid && v$.password.$dirty }"
            toggleMask
            :feedback="false"
            class="h-[35px] w-full border-slate-300 bg-white text-sm font-normal text-slate-500"
          />
          <div
            v-if="v$.password.$dirty && v$.password.$invalid"
            class="p-error text-xs text-red-600"
          >
            <div v-if="(v$.password.required as any)?.$invalid">Password is required.</div>
            <div v-else-if="(v$.password.minLength as any)?.$invalid">
              Password must be at least 8 characters long.
            </div>
          </div>
        </div>
      </div>
      <div class="inline-flex items-start justify-start gap-[7px] self-stretch rounded-md">
        <Checkbox
          v-model="acceptTerms"
          @blur="v$.acceptTerms.$touch()"
          :class="{ 'p-invalid': v$.acceptTerms.$invalid && v$.acceptTerms.$dirty }"
          class="relative top-[6px]"
          inputId="acceptTerms"
          name="acceptTerms"
          :binary="true"
        />
        <label for="acceptTerms" class="flex-1 justify-start">
          <span class="text-xs font-normal leading-[17.50px] text-slate-700"
            >I have read and accept 4C Predictions&nbsp;</span
          ><router-link
            to="/terms-of-use"
            class="text-xs font-normal leading-[17.50px] text-slate-700 underline"
            >Terms and Conditions</router-link
          ><span class="text-xs font-normal leading-[17.50px] text-slate-700"> and&nbsp;</span
          ><router-link
            to="/privacy-policy"
            class="text-xs font-normal leading-[17.50px] text-slate-700 underline"
            >Privacy Policy.</router-link
          >
        </label>
      </div>
      <div
        v-if="v$.acceptTerms.$dirty && v$.acceptTerms.$invalid"
        class="p-error mb-1 text-xs text-red-600"
      >
        <div v-if="(v$.acceptTerms.required as any)?.$invalid">
          You must accept terms & conditions
        </div>
      </div>
      <Button
        type="submit"
        :disabled="authStore.isLoading"
        class="mt-3 w-full px-[10.50px] py-[7px] shadow"
      >
        <i v-if="authStore.isLoading" class="pi pi-spin pi-spinner"></i>
        <div class="text-xl font-bold">Get Your Free Brackets now!</div>
      </Button>
    </form>
  </MarchMadnessLayout>
</template>
