import { useLocalStorage } from "@vueuse/core";
import axios, { type CancelTokenSource } from "axios";
import CryptoJS from "crypto-js";

const secretKey = import.meta.env.VITE_APP_SECRET || "";
const baseUrl = import.meta.env.VITE_API_BASE_URL || "https://api.4cpredictions.ai";

let cancelTokenSource: CancelTokenSource | null = null;
const pendingToast = useLocalStorage("pendingToast", null);

function getCleanPath(url: string) {
  const urlObj = new URL(url, baseUrl);
  return urlObj.pathname;
}

const axiosInstance = axios.create({
  timeout: 30000,
  baseURL: baseUrl,
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const apiPath = getCleanPath(config.url || "/");
    const timestamp = Math.floor(Date.now() / 1000);
    const payload = timestamp + apiPath;
    const signature = CryptoJS.HmacSHA256(payload, secretKey).toString();

    config.headers["X-TIMESTAMP"] = timestamp;
    config.headers["X-REQUEST-SIGNATURE"] = signature;
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    config.headers["Accept"] = "application/json";

    const isFormData = config.data instanceof FormData;
    if (!isFormData) {
      config.headers["Content-Type"] = "application/json";
    }

    // Attach Bearer Token if available
    const session_user = localStorage.getItem("4c_ath_tkn");
    if (session_user) {
      const { access_token } = JSON.parse(session_user);
      if (access_token) {
        config.headers["Authorization"] = `Bearer ${access_token}`;
      }
    }

    // Create a new cancel token for each request
    cancelTokenSource = axios.CancelToken.source();
    config.cancelToken = cancelTokenSource.token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    //const errMsg = "An error occured, please try again.";
    // if (error.response && error.response.data.errors) {
    //   errMsg = error.response.data.errors;
    // }
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("4c_ath_tkn");

      pendingToast.value = JSON.stringify({
        severity: "error",
        summary: "Error",
        detail: "Session expired",
        life: 10000,
      });

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Unauthorized. Cancelling all requests.");
        cancelTokenSource = null;
      }
      window.location.href = "/";
    }
    //showGlobalToast("error", "Error", errMsg);
    return Promise.reject(error);
  },
);

export default axiosInstance;
