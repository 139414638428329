<template>
  <Dialog :visible="showDialog" :style="{ maxWidth: '420px', width: '100%' }" modal :closable="false"
    :closeOnEscape="false">
    <template #header>
      <div class="inline-flex w-full items-start justify-between">
        <div class="text-2xl font-bold text-slate-700">
          Cancel Subscription
        </div>
        <Button @click="closeDialog"
          class="flex h-[33px] w-[33px] items-center justify-center rounded-md border border-slate-200 bg-transparent p-0">
          <div class="relative h-3.5 w-3.5 overflow-hidden">
            <SvgIcon name="close" />
          </div>
        </Button>
      </div>
    </template>

    <div class="flex w-full flex-col items-start justify-start gap-4">
      <div class="w-full">
        <label class="block mb-2 text-sm font-medium text-gray-700">Reason for cancellation</label>
        <div class="space-y-3 w-full">
          <div v-for="option in options" :key="option.value" class="flex items-start w-full">
            <div class="flex-shrink-0 pt-1">
              <input type="radio" :id="option.value" :value="option.value" v-model="selectedOption"
                class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
            </div>
            <label :for="option.value" class="ml-3 text-sm text-gray-700 cursor-pointer leading-snug w-full">
              {{ option.label }}
            </label>
          </div>
        </div>
      </div>

      <div v-if="selectedOption === 'Other'" class="w-full">
        <label class="block mb-2 text-sm font-medium text-gray-700">Please specify:</label>
        <textarea ref="otherTextareaRef" v-model="otherReason" maxlength="255" rows="3"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2"></textarea>
      </div>

      <div
        class="flex flex-col items-center justify-center gap-2 self-stretch rounded-lg bg-sky-50 p-4 outline outline-1 outline-offset-[-1px] outline-sky-500">
        <div class="inline-flex items-center justify-between self-stretch">
          <div class="text-center">
            <span class="text-sm font-semibold text-slate-700">
              Are you sure you want to cancel your subscription?
            </span>
          </div>
        </div>
        <div class="flex w-full items-start justify-start gap-5">
          <Button @click="handleCancelSubscription" :disabled="!canSubmit"
            class="flex w-full flex-1 items-center justify-center gap-1 rounded-md bg-red-500 px-[7px] py-3 disabled:opacity-50 disabled:cursor-not-allowed">
            <i v-if="loading" class="pi pi-spin pi-spinner"></i>
            <div class="text-sm font-semibold text-white">Yes</div>
          </Button>
          <Button @click="closeDialog"
            class="flex w-full flex-1 items-center justify-center gap-1 rounded-md bg-sky-500 px-[7px] py-3">
            <div class="text-sm font-semibold text-white">No</div>
          </Button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch, nextTick } from "vue";
import SvgIcon from "../SvgIcon.vue";
import { userApi } from "@/api/userApi";
import { showGlobalToast } from "@/utils/toast";
import router from "@/router";

const props = defineProps<{
  showDialog: boolean;
  subscriptionType: 'new' | 'old';
}>();

const emit = defineEmits(['update:showDialog']);

const loading = ref(false);
const selectedOption = ref('');
const otherReason = ref('');
const otherTextareaRef = ref<HTMLTextAreaElement | null>(null);

const options = [
  { value: 'understand', label: 'I don’t fully understand how 4C Predictions works' },
  { value: 'accuracy', label: 'I expected better prediction accuracy' },
  { value: 'expensive', label: 'It’s too expensive for me right now' },
  { value: 'not_using', label: 'I’m not currently betting or using predictions' },
  { value: 'no_value', label: 'I didn’t see the value in the models I tried' },
  { value: 'technical', label: 'I had a technical issue or poor experience' },
  { value: 'Other', label: 'Other (please tell us more)' },
];

const closeDialog = () => {
  emit('update:showDialog', false);
  selectedOption.value = '';
  otherReason.value = '';
};

const canSubmit = computed(() => {
  if (selectedOption.value === '') return false;
  if (selectedOption.value === 'Other' && otherReason.value.trim() === '') return false;
  return true;
});

const cancelSubscription = async () => {
  const reason = selectedOption.value === 'Other'
  ? otherReason.value
  : options.find(option => option.value === selectedOption.value)?.label ?? selectedOption.value;


  try {
    loading.value = true;
    const result = await userApi.cancelStripeSubscription({ reason });

    if (result.data.success) {
      closeDialog();
      router.push({ name: 'profile-cancel-subscription-result', query: { subscriptionType: 'new' } });
    } else {
      console.error("error cancelling subscription", result.data.error);
      closeDialog();
      router.push({
        name: 'profile-cancel-subscription-result',
        query: {
          subscriptionType: 'new',
          error: 'true'
        }
      });
    }
  } catch (error) {
    console.error(error);
    closeDialog();
    router.push({
      name: 'profile-cancel-subscription-result',
      query: {
        subscriptionType: 'new',
        error: 'true'
      }
    });
  } finally {
    closeDialog();
  }
};

const handleCancelSubscription = async () => {
  if (props.subscriptionType === 'new') {
    await cancelSubscription();
  } else {
    await requestOldSubscriptionCancellation();
  }
};

const requestOldSubscriptionCancellation = async () => {
  const reason = selectedOption.value === 'Other'
  ? otherReason.value
  : options.find(option => option.value === selectedOption.value)?.label ?? selectedOption.value;


  try {
    loading.value = true;
    const result = await userApi.cancelSubscription({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      message: reason,
    });

    if (result) {
      closeDialog();
      router.push({ name: 'profile-cancel-subscription-result', query: { subscriptionType: 'old' } });
    } else {
      console.log('Error requesting subscription cancellation');
    }
  } catch (error) {
    console.log("Error requesting subscription cancellation");
    console.error(error);
    showGlobalToast("error", "", "Unable to request subscription cancellation.");
  } finally {
    loading.value = false;
    router.push({ name: 'profile-cancel-subscription-result', query: { subscriptionType: 'old' } });
  }

};

watch(selectedOption, async (newValue) => {
  if (newValue !== 'Other') {
    otherReason.value = '';
  } else {
    await nextTick();
    otherTextareaRef.value?.focus();
  }
});
</script>
