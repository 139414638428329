<template>
  <div class="flex flex-col items-center gap-4">
    <input type="file" accept="image/*" class="hidden" ref="fileInput" @change="onFileChange" />

    <Button
      label="Upload Profile Picture"
      icon="pi pi-image"
      @click="fileInput?.click()"
    />

    <div v-if="imageSrc" class="relative h-64 w-64">
      <Cropper
        ref="cropperRef"
        :src="imageSrc"
        :stencil-props="{
          aspectRatio: 1,
          movable: true,
          scalable: false,
          resizable: false,
          size: { width: 250, height: 250 },
        }"
        class="h-full w-full"
      />
    </div>

    <div v-if="imageSrc" class="mt-2 flex gap-2">
      <Button label="Done" icon="pi pi-check" class="h-8 !text-sm" @click="cropImage" />
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-danger h-8 !text-sm"
        @click="reset"
      />
    </div>

    <div v-if="avatar" class="mt-4">
      <img :src="avatar" class="h-24 w-24 rounded-full border border-primary shadow" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

const avatar = defineModel<string | null>("avatar");

const fileInput = ref<HTMLInputElement>();
const imageSrc = ref<string | null>(null);
const cropperRef = ref<InstanceType<typeof Cropper> | null>(null);

function onFileChange(e: Event) {
  const file = (e.target as HTMLInputElement).files?.[0];
  if (!file) return;

  const reader = new FileReader();
  reader.onload = () => {
    imageSrc.value = reader.result as string;
  };
  reader.readAsDataURL(file);
}

function cropImage() {
  const canvas = cropperRef.value?.getResult()?.canvas;
  if (canvas) {
    avatar.value = canvas.toDataURL("image/webp", 0.8);
    imageSrc.value = null;
  }
}

function reset() {
  imageSrc.value = null;
  avatar.value = null;
}
</script>

<style scoped>
.cropper {
  border-radius: 9999px;
  overflow: hidden;
}
</style>
